import React from "react";
import { Option, Value } from "./dietary_restriction_field";

type Props = {
  options: Option[];
  values: Value[];
  isNoRestrictions: boolean;
};

const DietaryRestrictionViewer: React.FC<Props> = ({ options, values, isNoRestrictions }) => {
  const selected = options.filter(([value, _label]) => values.includes(value));

  return (
    <div className="c-multipleCheck_input">
      <span>
        <i className="fas fa-chevron-down"></i>
      </span>
      {selected.length === 0 && !isNoRestrictions ? (
        <span>{window.i18n.t('components.user.new_reservation_requests.dietary_restriction_viewer.multiplecheck.option.default')}</span>
      ) : (
        <div className="c-multipleCheck_checked">
          {isNoRestrictions && (
            <div className="c-multipleCheck_item">{window.i18n.t('components.user.new_reservation_requests.dietary_restriction_viewer.multiplecheck.option.is_no_restriction')}</div>
          )}
          {selected.map(([value, label]) => (
            <div key={value} className="c-multipleCheck_item">
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DietaryRestrictionViewer;
