import * as React from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';

import StripeCardForm from './StripeCardForm';
import FlashMessage from './FlashMessage';
import { formControlContext, FormFieldState } from "./user/new_reservation_requests/form_control_context";

export type StripeSourceCard = {
  id: string,
  last4: string,
  brand: string,
  brandImagePath: string,
};

type Props = {
  stripePublicKey: string,
  stripeSourceCard: StripeSourceCard | null,
  isTemporaryReservation: boolean,
};

type State = {
  newStripeSourceCard: StripeSourceCard | null,
  isOpenUpdateStripe: boolean,
  cardUpdateSuccessMessage: string,
};

class StripeCardField extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;
  private stripePromise: Promise<Stripe | null>;

  constructor(props: Props) {
    super(props);

    this.stripePromise = loadStripe(props.stripePublicKey);

    let temporaryCardInfo = {};
    if (props.isTemporaryReservation) {
      temporaryCardInfo = {
        cardNumber: '',
        nameOnTheCard: '',
        validthrough: '',
        cvc: '',
      };
    }

    this.state = {
      newStripeSourceCard: null,
      isOpenUpdateStripe: false,
      cardUpdateSuccessMessage: '',
      ...temporaryCardInfo,
    };
  }

  static contextType = formControlContext;

  componentDidMount() {
    if (this.state.newStripeSourceCard || this.props.stripeSourceCard) {
      this.context.setIsReadyStripeCard(true);
    }
  }

  render() {
    const {
      stripeSourceCard,
    } = this.props;
    const {
      newStripeSourceCard,
    } = this.state;

    if (!!this.state.cardUpdateSuccessMessage) {
      this.context.setIsReadyStripeCard(true)
    }

    const paymentMethodSection = <div>
      <div className="row">
        <div className="col">
          <div className="p-checkout_card">
            {(newStripeSourceCard || stripeSourceCard) ? (
              <>
                <div className="p-checkout_card_detail">
                  <img src={newStripeSourceCard?.brandImagePath || stripeSourceCard.brandImagePath} />
                  <span>・・・・ ・・・・ ・・・・ {newStripeSourceCard?.last4 || stripeSourceCard.last4}</span>
                </div>
                <a href='#'
                  className={
                    ['btn', 'btn-sm', 'btn-outline-primary'].concat(this.context.isFormSending ? ['disabled'] : []).join(' ')
                  }
                  onClick={async (e) => {
                    e.preventDefault()
                    this.setState({ isOpenUpdateStripe: true }, () => this.validate)
                  }}
                >{window.i18n.t('components.stripe_card_field.update_payment_method.button_text')}</a>
              </>
            ) : (
              <>
                <a href="#"
                  className={
                    ['btn', 'btn-primary'].concat(this.context.isFormSending ? ['disabled'] : []).join(' ')
                  }
                  onClick={async (e) => {
                    e.preventDefault()
                    this.setState({ isOpenUpdateStripe: true }, () => this.validate)
                  }}
                >{window.i18n.t('components.stripe_card_field.add_credit_card.button_text')}</a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>;

    return (
      <Elements stripe={this.stripePromise}>
        <input
          type='hidden'
          name='displayed_stripe_source_id'
          id='displayed_stripe_source_id'
          value={(newStripeSourceCard && newStripeSourceCard.id) || (stripeSourceCard && stripeSourceCard.id)}
        />
        {paymentMethodSection}
        <Modal
          show={this.state.isOpenUpdateStripe}
          onHide={() => this.setState({ isOpenUpdateStripe: false })}
          className='p-checkout_ccModal'
        >
          <Modal.Header closeButton>
            {newStripeSourceCard || stripeSourceCard ? `${window.i18n.t('components.stripe_card_field.checkout_ccmodal.close_button.is_update')}` : `${window.i18n.t('components.stripe_card_field.checkout_ccmodal.close_button.add_credit_card')}`}
          </Modal.Header>
          <Modal.Body>
            {newStripeSourceCard || stripeSourceCard ? (
              <p className="p-checkout_ccModal_caution">
                <i className="fas fa-exclamation-circle me-1"></i>
                {window.i18n.t('components.stripe_card_field.checkout_ccmodal.caution')}
              </p>
            ) : null}
            <StripeCardForm
              isTemporaryReservation={this.props.isTemporaryReservation}
              isUpdate={!!newStripeSourceCard || !!stripeSourceCard}
              setNewStripeSourceCard={(newStripeSourceCard: StripeSourceCard) => { this.setState({ newStripeSourceCard }) }}
              setCardUpdateSuccessMessage={(message: string) => this.setState({ cardUpdateSuccessMessage: message })}
              closeModal={() => this.setState({ isOpenUpdateStripe: false })}
            />
          </Modal.Body>
        </Modal>
        {!!this.state.cardUpdateSuccessMessage && (
          <FlashMessage type="notice" message={this.state.cardUpdateSuccessMessage} />
        )}
      </Elements>
    );
  }
};

export default StripeCardField;
