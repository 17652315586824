/**
 * コンシェルジュ向け在庫リストローディングコンポーネント実装
 */
import React from "react";

const AvailableOnlineStockGroupLoading: React.FC = () => {
  return (
    <>
      <div className={'w-100'}>
        <table className={'table table-sm c-aTable rounded-0'}>
          <tbody className={'placeholder-glow'}>
          {Array.from({length: 2 /* ランチとディナー枠 */}, (_, k) => k).map((index) => {
            return (
              <tr key={index}>
                <td>
                  <span className='placeholder bg-placeholder placeholder-lg col-12'></span>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AvailableOnlineStockGroupLoading
