import React, {useState}  from 'react';
import ImageGalleryModal from "./image_gallery.tsx";

type ImageFieldProps = {
  images: string[];
  reviewer: string;
};

const ImageField: React.FC<ImageFieldProps> = ({ images, reviewer }) => {

  const [modalShow, setModalShow] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleShowImages = (review, idx) => {
    setSelectedReviewer(reviewer);
    setSelectedImageIndex(idx);
    setModalShow(true);
  };

  return (
    <>
      <div className='p-rShow_reviews_photos'>
        <ul>
          {images.map((imageSrc, idx) => (
            <li key={idx} onClick={() => handleShowImages(reviewer, idx)}>
              <img src={imageSrc} alt={`${reviewer}'s review image (${idx + 1})`} loading="lazy" />
            </li>
          ))}
        </ul>
      </div>
      <ImageGalleryModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        reviewer={selectedReviewer}
        images={images}
        selectedImageIndex={selectedImageIndex}
        />
    </>
  );
};

export default ImageField;
