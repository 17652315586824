import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import snakecaseKeys from "snakecase-keys";
import camelcaseKeys from "camelcase-keys";
import DatePicker from "react-datepicker";
import differenceBy from 'lodash/differenceBy'
import flatMap from 'lodash/flatMap'
import intersectionBy from 'lodash/intersectionBy'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import uniq from 'lodash/uniq'
import Rails from "rails-ujs";
import { Course } from "../../types/course";
import { SeatType } from "../../types/seat_type";
import { TimeSlot } from "../../types/time_slot";
import { InstantReservationSlotGroup } from "../../types/instant_reservation_slot_group";
import { bpMobile, monthInMs, seatTypeEmpty } from "../../utils/constants";
import {
  getDatesBetween,
  newDateFromDateString,
  toISODateString,
} from "../../utils/dates";
import { SeatCategory } from "../../types/seat_category";
import { format } from 'date-fns'
import { enUS, ko, zhCN, zhTW } from 'date-fns/locale';

interface Props {
  restaurantSlug: string;
  restaurantName: string;
  defaultDate: string | null;
  defaultGuestsCount: number;
  defaultSelectableGuestsCount: number[];
  seatTypeAnyTitles: { [key: string]: string };
  noIRSGsMessage: string;
  noSeatTypeMessage: string;
  isAgentSignedIn: boolean;
  isRequestable: boolean;
  costs: { [key: string]: string };
  reservationUidInCart: string | null;
  hasInstantReservations: boolean;
  isIrsOnly: boolean;
  hasRequestableIrsg: boolean;
  isRrssAlreadySet: boolean;
}

const NewReservationForm: React.FC<Props> = ({
  restaurantSlug,
  restaurantName,
  defaultDate,
  defaultGuestsCount,
  defaultSelectableGuestsCount,
  seatTypeAnyTitles,
  noIRSGsMessage,
  noSeatTypeMessage,
  isAgentSignedIn,
  isRequestable,
  costs,
  reservationUidInCart,
  hasInstantReservations,
  isIrsOnly,
  hasRequestableIrsg,
  isRrssAlreadySet,
}) => {
  const [date, setDate] = useState<Date | undefined>(() => {
    try {
      return newDateFromDateString(defaultDate || "");
    } catch (error) {
      // NOTE: パースエラーは無視する
    }
  });

  const [hasAlreadyBeenRemovedIRSGs, setHasAlreadyBeenRemovedIRSGs] = useState<boolean>(false);

  const [guestsCount, setGuestsCount] = useState<number>(defaultGuestsCount);
  const [timeSlot, setTimeSlot] = useState<TimeSlot | undefined>();
  const [course, setCourse] = useState<Course | undefined>();
  const [seatType, setSeatType] = useState<SeatType | undefined>();

  const [selectableGuestsCounts, setSelectableGuestsCounts] = useState<number[]>(defaultSelectableGuestsCount);
  const [selectableTimeSlots, setSelectableTimeSlots] = useState<TimeSlot[]>([]);
  const [selectableCourses, setSelectableCourses] = useState<Course[]>([]);
  const [selectableSeatTypes, setSelectableSeatTypes] = useState<SeatType[]>([]);

  const [filteredTimeSlots, setFilteredTimeSlots] = useState<TimeSlot[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);
  const [filteredSeatTypes, setFilteredSeatTypes] = useState<SeatType[]>([]);

  const [excludedDates, setExcludedDates] = useState<Date[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(bpMobile);
  const [isSeatSelectable, setIsSeatSelectable] = useState<boolean>(false);
  const [reservationSlotGroup, setReservationSlotGroup] = useState<
    InstantReservationSlotGroup | undefined
  >();
  const [isValidIrsParams, setIsValidIrsParams] = useState<boolean>(false);
  const datePicker = useRef<DatePicker>();

  const [reservationSlotGroups, setReservationSlotGroups] = useState<InstantReservationSlotGroup[]>([]);
  const [instantReservationDates, setInstantReservationDates] = useState<string[]>([]);
  const [isSelectedIrsDate, setIsSelectedIrsDate] = useState<boolean>(false);

  const [selectableDataByStartTime, setSelectableDataByStartTime] = useState({});

  useEffect(() => {
    fetchAvailableDates(date || new Date());
  }, []);

  useEffect(() => {
    const _isSelectedIrsDate = !!date ? instantReservationDates.includes(toISODateString(date)) : false;
    fetchAvailableOptions(date, guestsCount, _isSelectedIrsDate);
    responsive();
    setIsSelectedIrsDate(_isSelectedIrsDate);
    window.addEventListener("resize", responsive);
    return () => {
      window.removeEventListener("resize", responsive);
    };
  }, [excludedDates]);

  const extractAndAssignGuestsData = (
    _date: Date,
    _guestsCount: number,
  ) => {
    const _isSelectedIrsDate = !!_date ? instantReservationDates.includes(toISODateString(_date)) : false;
    setIsSelectedIrsDate(_isSelectedIrsDate);
    fetchAvailableOptions(_date, _guestsCount, _isSelectedIrsDate);
  };

  const extractAndAssignReservationData = (
    _timeSlot: TimeSlot | undefined,
    _course: Course | undefined,
    _seatType: SeatType | undefined,
  ) => {
    let definedCourses = undefined, definedTimeSlots = undefined;
    if (isSelectedIrsDate) {
      definedTimeSlots = selectableTimeSlots;
      definedCourses = selectableCourses.filter((c) => _timeSlot?.mealCategory === c.mealCategory);
    } else {
      definedTimeSlots = selectableTimeSlots
        .sort(
          (_timeSlotA, _timeSlotB) =>
            new Date(_timeSlotA.startTime || "").getTime() -
            new Date(_timeSlotB.startTime || "").getTime()
        );

      definedCourses = selectableCourses.filter((option) =>
        isEmpty(_timeSlot) ? true : option.mealCategory === _timeSlot?.mealCategory
      );
      if (!isEmpty(seatType)) {
        definedCourses = definedCourses.filter((c: Course) =>
          isEmpty(c.availableSeatTypes) ||
          (!!selectableDataByStartTime[_timeSlot.startTimeLabel][c.uid])
        );
      }
    }

    setReservationData(date, guestsCount, _timeSlot, _course, _seatType, definedTimeSlots, definedCourses, selectableSeatTypes, selectableDataByStartTime, isSelectedIrsDate, reservationSlotGroups);
  };

  const setReservationData = (
    _date: Date | undefined,
    _guestsCount: number,
    _timeSlot: TimeSlot | undefined,
    _course: Course | undefined,
    _seatType: SeatType | undefined,
    _timeSlots: TimeSlot[],
    _courses: Course[],
    _seatTypes: SeatType[],
    _selectableDataByStartTime: {} | undefined,
    _isSelectedIrsDate: boolean,
    _reservationSlotGroups: InstantReservationSlotGroup[],
  ) => {
    const seatCategories: SeatCategory[] = (!!_course && _course.availableSeatTypes?.length > 0) ? _course.availableSeatTypes : [];
    setFilteredTimeSlots(_timeSlots);
    let _filteredCourses: Course[];
    let _filteredSeatTypes: SeatType[];

    if (_timeSlots.length === 1) {
      _timeSlot = _timeSlots[0];
      setTimeSlot(_timeSlot);
    } else if (!!_timeSlot && !_timeSlots.find((ts => ts.uid === _timeSlot?.uid))) {
      _timeSlot = undefined;
      setTimeSlot(_timeSlot);
    }

    if (!!_timeSlot) {
      if (_selectableDataByStartTime && !!_selectableDataByStartTime[_timeSlot.startTimeLabel]) {
        _filteredCourses = _courses.filter((c) => !!_selectableDataByStartTime[_timeSlot.startTimeLabel][c.uid]);
      } else {
        _filteredCourses = _courses.filter((c) => c.mealCategory === _timeSlot?.mealCategory);
      }
      setFilteredCourses(_filteredCourses);

      if (_filteredCourses.length === 1) {
        _course = _filteredCourses[0];
        setCourse(_course);
      } else if (!_filteredCourses.find((c => c.uid === _course?.uid))) {
        _course = undefined;
        setCourse(undefined);
      }
    } else {
      setFilteredCourses([]);
      setCourse(undefined);
    }

    if (!!_timeSlot && !!_course) {
      if (_isSelectedIrsDate) {
        const _seatCategories = uniq(flatMap(_reservationSlotGroups.map((rs) => {
          if (rs.timeSlots.map((ts) => ts.uid).includes(_timeSlot?.uid) && rs.courses.map((c) => c.uid).includes(_course?.uid) && rs.guestsCountOptions.includes(_guestsCount)) {
            return rs.seatCategory;
          }
        })).filter((sc) => !!sc));
        _filteredSeatTypes = _seatTypes.filter((st) => _seatCategories.includes(st.seatCategory));
      } else if (_selectableDataByStartTime && !!_selectableDataByStartTime[_timeSlot.startTimeLabel] && !!_selectableDataByStartTime[_timeSlot.startTimeLabel][_course.uid]) {
        _filteredSeatTypes = selectableSeatTypes.filter((st) => _selectableDataByStartTime[_timeSlot.startTimeLabel][_course.uid]?.seatCategories.includes(st.seatCategory));
      } else {
        _filteredSeatTypes = seatCategories.length > 0
          ? _seatTypes.filter((st) => seatCategories.includes(st.seatCategory))
          : _seatTypes;
      }

      if (!seatType || _filteredSeatTypes.length === 1) {
        _seatType = _filteredSeatTypes[0];
        setSeatType(_seatType);
      }

      setFilteredSeatTypes(_filteredSeatTypes);
    } else {
      setFilteredSeatTypes([]);
      setSeatType(undefined);
    }

    checkInstantReservable(
      _reservationSlotGroups,
      _date,
      _guestsCount,
      _timeSlot,
      _course,
      _seatType
    );
  };

  const checkInstantReservable = (
    _reservationSlotGroups: InstantReservationSlotGroup[],
    _date?: Date,
    _guestsCount?: number,
    _timeSlot?: TimeSlot,
    _course?: Course,
    _seatType?: SeatType
  ) => {
    const mc = _course?.mealCategory || _timeSlot?.mealCategory;
    const rsgs = _reservationSlotGroups.filter(
      (rs) => (isNil(_date) || (!!mc && mc === rs.mealCategory && rs.date === toISODateString(_date)))
    );

    if (rsgs.length === 0) {
      setReservationSlotGroup(undefined);
      setIsValidIrsParams(false);
      return;
    }

    const checkedIrss = rsgs.filter(
      (rs) =>
        (!isNil(_guestsCount) && rs.guestsCountOptions.includes(_guestsCount)) &&
        (!isNil(_timeSlot) &&
          rs.timeSlots
            .map((rsTimeSlot) => rsTimeSlot.startTimeLabel)
            .includes(_timeSlot.startTimeLabel)) &&
        (!isNil(_course) &&
          rs.courses
            .map((rsCourse) => rsCourse.mealCategory)
            .includes(_course.mealCategory)) &&
        (!isNil(_seatType) && rs.seatCategory === _seatType.seatCategory)
    );

    if (checkedIrss.length >= 1) {
      setReservationSlotGroup(checkedIrss[0]);
      setIsValidIrsParams(true);
    } else {
      setReservationSlotGroup(undefined);
      setIsValidIrsParams(false);
    }
  };

  const fetchAvailableOptions = async (date?: Date, guestsCount?: number, _isSelectedIrsDate?: boolean) => {
    const url = `${window.i18n.locale_path}/restaurants/${restaurantSlug}/inquire`;
    if (!date || !guestsCount) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.get(url, {
        params: snakecaseKeys({ date: toISODateString(date), guestsCount })
      });
      if (response.status === 200) {
        const data = camelcaseKeys(response.data, { deep: true });
        const mealCategories = intersectionBy<TimeSlot | Course>(
          data.timeSlots,
          data.courses,
          "mealCategory"
        ).map((elem) => elem.mealCategory);
        const _isSeatSelectable = data.isSeatSelectable && (data.existsUserSelectableSeats || data.existsRestaurantSelectableSeats);
        setIsSeatSelectable(_isSeatSelectable);

        let _selectableTimeSlots = undefined, _selectableCourses = undefined, _selectableSeatTypes = undefined, _selectableDataByStartTime = undefined;
        let rsgs = [], _selectableTimeSlotUids = [];

        if (_isSelectedIrsDate === true && (!data.reservationSlotGroups || data.reservationSlotGroups.length === 0 || data.reservationSlotGroups.every((rsg) => rsg.guestsCountOptions.length === 0))) {
          setInstantReservationDates(instantReservationDates.filter((d) => d !== toISODateString(date)));
          _isSelectedIrsDate = false;
          setHasAlreadyBeenRemovedIRSGs(true);

          setExcludedDates(excludedDates.push(date));
          setDate(undefined);
          fetchAvailableDates(new Date());
          throw new Error('No instant reservation slots.');
        }

        if (!!data.reservationSlotGroups) {
          rsgs = data.reservationSlotGroups.filter((rs) => rs.date === toISODateString(date));
          setReservationSlotGroups(rsgs);

          setSelectableGuestsCounts(uniq(flatMap(rsgs.map((rs) => rs.guestsCountOptions))).sort((a, b) => a - b));

          const info = {};
          data.reservationSlotGroups.forEach((rs) => {
            // NOTE: 選択したguestsCountが含まれない場合はスキップ
            if (!rs.guestsCountOptions.includes(guestsCount)) return;

            rs.timeSlots.forEach((ts) => {
              if (!_selectableTimeSlotUids.some((uid) => uid === ts.uid)) {
                _selectableTimeSlotUids.push(ts.uid);
              }

              if (!info[ts.startTimeLabel]) info[ts.startTimeLabel] = {};
              rs.courses.forEach((c) => {
                if (!info[ts.startTimeLabel][c.uid]) info[ts.startTimeLabel][c.uid] = { seatCategories: [] };
                info[ts.startTimeLabel][c.uid].seatCategories.push(rs.seatCategory);
                info[ts.startTimeLabel][c.uid].seatCategories = Array.from(new Set(info[ts.startTimeLabel][c.uid].seatCategories));
              });
            });
          });

          _selectableDataByStartTime = info
          setSelectableDataByStartTime(info);
        } else if (!isRrssAlreadySet) {
          setSelectableGuestsCounts(defaultSelectableGuestsCount);
        }

        // NOTE: 並び順を担保したデータを取得
        if (_selectableTimeSlotUids.length > 0) {
          _selectableTimeSlots = data.timeSlots.filter((ts) => _selectableTimeSlotUids.includes(ts.uid));
        } else {
          _selectableTimeSlots = data.timeSlots;
        }
        setSelectableTimeSlots(_selectableTimeSlots);

        _selectableCourses = data.courses;
        setSelectableCourses(_selectableCourses);

        // TODO: リクエスト枠が充実してきたら処理を見直す ここから ===
        //       See: https://github.com/omakasejapan/inbound/issues/2305
        _selectableSeatTypes = data.seatTypes;
        if (!_isSeatSelectable && _selectableSeatTypes.length === 0) {
          seatTypeEmpty["title"] = seatTypeAnyTitles["restaurant"];
          _selectableSeatTypes = [seatTypeEmpty];
        }
        setSelectableSeatTypes(_selectableSeatTypes);
        // TODO: リクエスト枠が充実してきたら処理を見直す ここまで ===

        setReservationData(date, guestsCount, timeSlot, course, seatType, _selectableTimeSlots, _selectableCourses, _selectableSeatTypes, _selectableDataByStartTime, _isSelectedIrsDate, rsgs);
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      console.error(error);
      setSelectableTimeSlots([]);
      setSelectableCourses([]);
      setSelectableSeatTypes([]);
      return (false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableDates = async (month: Date) => {
    const url = `${window.i18n.locale_path}/candidate_dates?slug=${restaurantSlug}`;
    try {
      const today = new Date();
      const toMonth = new Date(month.getTime() + monthInMs);
      const allDates = getDatesBetween(today, toMonth);
      setExcludedDates(allDates);
      const response = await axios.get(url, {
        params: snakecaseKeys({
          startDate: toISODateString(today),
          endDate: toISODateString(new Date(month.getTime() + monthInMs)),
        }),
      });
      if (response.status === 200) {
        const availableDates = Array.from(
          response.data.requestAvailableDates
            .concat(response.data.irsAvailableDates || [])
        ).map((dateStr) => newDateFromDateString(dateStr));
        const excludedDates = differenceBy(
          allDates,
          availableDates,
          toISODateString
        );
        setInstantReservationDates(response.data.irsAvailableDates);
        setExcludedDates(excludedDates);
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const responsive = () => {
    setWidth(window.innerWidth);
  };

  const toggleDatePicker = () => {
    datePicker.current.setOpen(!datePicker.current.isCalendarOpen());
  };

  const canSubmit = [date, guestsCount > 0, course, seatType, timeSlot].every(
    (condition) => !!condition
  );

  const showDetailedForm = [
    selectableTimeSlots.length > 0,
    selectableCourses.length > 0,
    selectableSeatTypes.length > 0,
    !hasAlreadyBeenRemovedIRSGs,
  ].every((condition) => !!condition);

  const formActionUrl = `${window.i18n.locale_path}/${isAgentSignedIn ? "agent" : "user"
    }/new_reservation_requests/new`;
  const requestFormActionUrl = `${window.i18n.locale_path}/${
    isAgentSignedIn ? "agent" : "user"
  }/new_reservation_requests/new`;
  const instnatFormActionUrl = `${window.i18n.locale_path}/${
    isAgentSignedIn ? "agent" : "user"
  }/instant_reservations`;
  let reviewActionUrl = ''
  if (!!reservationUidInCart) {
    reviewActionUrl = `${window.i18n.locale_path}/${
      isAgentSignedIn ? "agent" : "user"
    }/instant_reservations/${reservationUidInCart}/review?restaurant_slug=${restaurantSlug}`;
  }

  // ブラウザローカルタイムゾーンの00:00:00がくっついた`date`を
  // タイムゾーンを変えることなく、"YYYY-MM-DD"形式の文字列に変換する
  const dateString =
    date &&
    [
      date.getFullYear().toString(),
      `0${date.getMonth() + 1}`.slice(-2),
      `0${date.getDate()}`.slice(-2),
    ].join("-");

  // NOTE: OSAが入っている&&g現在時刻以降の場合はリクエスト不可として扱う
  if (isRequestable === false) {
    return (
      <div className={`p-rShow_action ${isFormOpen ? "open" : ""}`}>
        <div className="p-rShow_reserve">
          <h3 className="c-titleS mb-3 pc-only">
            <i className="fas fa-arrow-circle-right me-2"></i>
            {window.i18n.t('components.restaurants.new_reservation_form.rshow_reserve.title')}
          </h3>
          <div className="p-reserveAction">
            <div className="alert alert-secondary">
              {window.i18n.t('components.restaurants.new_reservation_form.reserve_actoin.alert')}
            </div>
          </div>
        </div>
        <div className="p-rShow_mobile sp-only">
          <div className="p-rShow_mobile_contents">
            <div className="alert alert-secondary text-center mb-0">
              <b>{window.i18n.t('components.restaurants.new_reservation_form.rshow_mobile.alert')}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const selectableGuestsCountOptionData = (Array.from(new Set([...selectableGuestsCounts, guestsCount])).sort((a, b) => a - b));

  const getLocale = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return ko;
    if (path.startsWith('/zh-cn')) return zhCN;
    if (path.startsWith('/zh-tw')) return zhTW;
    return enUS; // default locale
  };

  const getDateFormat = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return 'M월 d일';
    if (path.startsWith('/zh-cn') || path.startsWith('/zh-tw')) return 'M月d日';
    return 'MMM d'; // default locale
  };

  const getWeekStartsOn = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return 0; // 日曜始まり
    return 1;
  };

  return (
    <>
      <div className={`p-rShow_action ${isFormOpen ? "open" : ""}`}>
        <div className="p-rShow_reserve">
          {!!reservationUidInCart ? <a
            href={reviewActionUrl}
            className="btn btn-primary btn-fluid btn-large"
          >
            {window.i18n.t('components.restaurants.new_reservation_form.rshow_action.button_text')}
          </a> : <div className="p-reserveAction">
            {isLoading && (
              <div className="p-reservationAction_spinner">
                <div className="p-reservationAction_spinner_wrapper">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{window.i18n.t('components.restaurants.new_reservation_form.rshow_action.loading')}</span>
                  </div>
                </div>
              </div>
            )}

            <div className="p-reserveAction_title">
              <div className="p-reserveAction_title_text">{restaurantName}</div>
              <span
                className="p-reserveAction_exit"
                onClick={() => setIsFormOpen(!isFormOpen)}
              >
                &#9587;
              </span>
            </div>

            <div
              className="p-reserveAction_item pointer react-datepicker-ignore-onclickoutside"
              onClick={toggleDatePicker}
            >

              <div className="display-flex">
                <div className="p-reserveAction_label_container">
                  <label className="p-reserveAction_item_label">
                    {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.date.label')}
                  </label>
                  <p className="p-reserveAction_label_ph">
                    {date
                      ? format(date, getDateFormat(), {locale: getLocale()})
                      :
                      <span>{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.date.label_ph')}</span>
                    }
                  </p>
                  {hasAlreadyBeenRemovedIRSGs && (
                      <p className="p-reserveAction_error">{noIRSGsMessage}</p>
                  )}
                </div>
                <div className="p-reserveAction_action pc-only">
                  <i className="fas fa-angle-down"></i>
                </div>
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <DatePicker
                  locale={getLocale()}
                  weekStartsOn={getWeekStartsOn()}
                  selected={date}
                  onChange={(date: Date) => {
                    setDate(date);
                    extractAndAssignGuestsData(date, guestsCount);
                    setHasAlreadyBeenRemovedIRSGs(false);
                  }}
                  onMonthChange={(month: Date) => fetchAvailableDates(month)}
                  ref={(_datePicker: DatePicker) =>
                    (datePicker.current = _datePicker)
                  }
                  customInput={<></>}
                  minDate={new Date()}
                  excludeDates={excludedDates}
                  dayClassName={(date: Date) =>
                    !excludedDates.find((excludedDate) => toISODateString(excludedDate) === toISODateString(date)) && instantReservationDates.includes(toISODateString(date))
                      ? "instant-reservation"
                      : "request-available"
                  }
                  popperClassName="custom-react-datepicker"
                  showPopperArrow={false}
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [0, 5],
                      },
                    },
                  ]}
                  inline={width < bpMobile}>
                  {(reservationSlotGroups.length > 0 || hasRequestableIrsg) && <ul className='p-reserveAction_desc'>
                    <li>
                      <span className="bg-ib"></span> {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.date.desc')}
                    </li>
                    <li>
                      <span className="bg-request"></span>{isIrsOnly ? `${window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.date.bg_request.request_not_available')}` : `${window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.date.bg_request.request_reservation')}`}
                    </li>
                  </ul>}
                </DatePicker>
              </div>
            </div>

            <div className="p-reserveAction_item pointer display-flex">
              <div className="p-reserveAction_label_container">
                <label className="p-reserveAction_item_label" htmlFor="p-reserveAction_select-party-size">{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.party_size.label')}</label>
                <p className="p-reserveAction_label_ph">
                  {guestsCount.toString()}
                  {guestsCount > 0 ?
                    guestsCount > 1 ?
                      ` ${window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.party_size.label_ph.is_plural')}`
                      : ` ${window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.party_size.label_ph.is_singular')}`
                    : ""}
                </p>
                {!selectableGuestsCounts.includes(guestsCount) && (
                  <p className="p-reserveAction_error">{noSeatTypeMessage}</p>
                )}
                {selectableGuestsCountOptionData.length > 1 && (
                  <select
                    id="p-reserveAction_select-party-size"
                    className="p-reserveAction_select"
                    value={guestsCount}
                    onChange={(event) => {
                      const selectedGuestsCount = parseInt(event.target.value);
                      setGuestsCount(selectedGuestsCount);
                      extractAndAssignGuestsData(date, selectedGuestsCount);
                    }}
                  >
                    {selectableGuestsCountOptionData.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {selectableGuestsCountOptionData.length > 1 && (
                <div className="p-reserveAction_action">
                  <i className="fas fa-angle-down"></i>
                </div>
              )}
            </div>

            {showDetailedForm &&
              selectableGuestsCounts.includes(guestsCount) && (
                <div className="p-reserveAction_item pointer display-flex">
                  <div className="p-reserveAction_label_container">
                    <label className="p-reserveAction_item_label">
                      {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.time_slot.label')}
                    </label>
                    <p className="p-reserveAction_label_ph">
                      {timeSlot?.startTimeLabel || (
                        <span>{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.time_slot.label_ph')}</span>
                      )}
                    </p>
                    {filteredTimeSlots.length > 1 && (
                      <select
                        className="p-reserveAction_select"
                        value={timeSlot?.uid || ""}
                        onChange={(event) => {
                          const newTimeSlot = filteredTimeSlots.find(
                            (elem) => event.target.value === elem.uid
                          );
                          setTimeSlot(newTimeSlot);
                          extractAndAssignReservationData(newTimeSlot, course, seatType);
                        }}
                      >
                        <option value="">{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.time_slot.opiton.default')}</option>
                        {filteredTimeSlots.map((option) => (
                          <option key={option.uid} value={option.uid}>
                            {option.startTimeLabel}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {filteredTimeSlots.length > 1 && (
                    <div className="p-reserveAction_action">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  )}
                </div>
              )}

            {showDetailedForm &&
              selectableGuestsCounts.includes(guestsCount) && (
                <div className="p-reserveAction_item pointer display-flex">
                  <div className="p-reserveAction_label_container">
                    <label className="p-reserveAction_item_label">{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.course.label')}</label>
                    <p className="p-reserveAction_label_ph">
                      {course?.title || <span>{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.course.label_ph')}</span>}
                    </p>
                    {filteredCourses.length > 1 && (
                      <select
                        className="p-reserveAction_select"
                        value={course?.uid || ""}
                        onChange={(event) => {
                          const newCourse = selectableCourses.find(
                            (elem) => event.target.value === elem.uid
                          );
                          setCourse(newCourse);
                          extractAndAssignReservationData(timeSlot, newCourse, seatType);
                        }}
                      >
                        <option value="">{window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.course.option.default')}</option>
                        {filteredCourses.map((option) => (
                          <option key={option.uid} value={option.uid}>
                            {option.title} ({costs[option.uid].toLocaleString()}
                            )
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {filteredCourses.length > 1 && (
                    <div className="p-reserveAction_action">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  )}
                </div>
              )}

            {showDetailedForm &&
              selectableGuestsCounts.includes(guestsCount) && (
                <div className="p-reserveAction_item pointer display-flex">
                  <div className="p-reserveAction_label_container">
                    <label className="p-reserveAction_item_label">
                      {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.seat_type.label')}
                    </label>
                    <p className="p-reserveAction_label_ph">
                      {seatType?.title || `${window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.seat_type.label_ph')}`}
                    </p>
                    {filteredSeatTypes.length > 1 && (
                      <select
                        className="p-reserveAction_select"
                        value={seatType?.uid || seatType?.seatCategory}
                        onChange={(event) => {
                          const newSeatType = filteredSeatTypes.find(
                            (elem) => (isSelectedIrsDate ? event.target.value === elem.seatCategory : event.target.value === elem.uid)
                          );
                          setSeatType(newSeatType);
                          extractAndAssignReservationData(timeSlot, course, newSeatType);
                        }}
                      >
                        {filteredSeatTypes.map((option) => (
                          <option key={option.seatCategory} value={isSelectedIrsDate ? option.seatCategory : option.uid}>
                            {option.title}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {filteredSeatTypes.length > 1 && (
                    <div className="p-reserveAction_action">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  )}
                </div>
              )}

            {!!date && (!isSelectedIrsDate || hasAlreadyBeenRemovedIRSGs) ? <form
              method="get"
              action={requestFormActionUrl}
              className="mt-3"
              data-turbo={false}
              key="requestForm"
            >
              <input
                type="hidden"
                name="restaurant_slug"
                value={restaurantSlug}
              />
              <input
                type="hidden"
                name="reservation_slot_group[uid]"
                value={reservationSlotGroup?.uid || ""}
              />
              <input type="hidden" name="guests_count" value={guestsCount} />
              <input type="hidden" name="date" value={dateString || ""} />
              <input
                type="hidden"
                name="course_uid"
                value={course?.uid || ""}
              />
              <input
                type="hidden"
                name="seat_type_uid"
                value={seatType?.uid || ""}
              />
              <input
                type="hidden"
                name="time_slot_uid"
                value={timeSlot?.uid || ""}
              />
              <button
                className="btn btn-primary btn-fluid btn-large"
                disabled={!canSubmit}
              >
                {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.submit.proceed_to_request.button_text')}
              </button>
            </form> : <form
              method="post"
              action={instnatFormActionUrl}
              className="mt-3"
              data-turbo={false}
              key="instantForm"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={Rails.csrfToken()}
              />
              <input
                type="hidden"
                name="reservation_slot_group[uid]"
                value={reservationSlotGroup?.uid || ""}
              />
              <input
                type="hidden"
                name="restaurant_slug"
                value={restaurantSlug}
              />
              <input type="hidden" name="guests_count" value={guestsCount} />
              <input type="hidden" name="date" value={dateString || ""} />
              <input
                type="hidden"
                name="course_uid"
                value={course?.uid || ""}
              />
              <input
                type="hidden"
                name="seat_type_uid"
                value={seatType?.uid || ""}
              />
              <input
                type="hidden"
                name="time_slot_uid"
                value={timeSlot?.uid || ""}
              />
              <button
                className="btn btn-primary btn-fluid btn-large"
                disabled={!isValidIrsParams}
              >
                {window.i18n.t('components.restaurants.new_reservation_form.reserveaction_item.submit.make_a_reservation.button_text')}
              </button>
            </form>}
          </div>}
        </div>
      </div>
      <div className="p-rShow_mobile sp-only">
        <div className="p-rShow_mobile_contents">
          <button
            className="btn btn-primary btn-fluid btn-large"
            onClick={() => setIsFormOpen(!isFormOpen)}
          >
            {window.i18n.t('components.restaurants.new_reservation_form.rshow_mobile.submit.button_text')}
          </button>
        </div>
      </div>
    </>
  );
};

export default NewReservationForm;
