import React, { useState } from 'react'
import htmlParse from 'html-react-parser';

interface Props {
  agreePath: string,
  termsContent: string,
};

const AgreementForm: React.FC<Props> = ({
  agreePath, termsContent,
}) => {
  const [agreementConfirmation, setAgreementConfirmation] = useState<boolean>(false);

  const handleScrollTermContent = e => {
    if (agreementConfirmation) return;
    const lowest = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
    if (lowest) {
      setAgreementConfirmation(true);
    }
  };

  return <>
    <div className='p-userTermsAgreement_content'>
      <div className='p-userTerms' onScroll={(e) => handleScrollTermContent(e)}>
        {htmlParse(termsContent)}
      </div>
    </div>
    <input
      type='checkbox'
      id='agreementConfirmationCheckbox'
      checked={agreementConfirmation}
      disabled={true}
      onChange={(e) => setAgreementConfirmation(e.target.checked)}
    />
    <label htmlFor="agreementConfirmationCheckbox" className="ms-3"><b>{window.i18n.t('components.terms.agreement_form.checkbox')}</b></label>
    <form method='post' action={agreePath} className='mt-3'>
      <button className='btn btn-primary btn-fluid btn-large' disabled={!agreementConfirmation}>{window.i18n.t('components.terms.agreement_form.button_text')}</button>
    </form>
  </>;
};

export default AgreementForm;
