import isEmpty from 'lodash/isEmpty';
import xorBy from 'lodash/xorBy';
import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { PriceRange, SearchEndpointParams, ServiceType } from '../../types/filter_params';
import { bpMobile, maxPrice } from '../../utils/constants';
// MEMO maxPrice()だと日本円の整数値, maxPrice(fxRate)だと外貨の実数値が返る
// 整数値はReactSliderの制御値として使う

interface Props {
  serviceTypeOptions: ServiceType[],
  fxRate: number,
  currency: string,
  defaultParams?: SearchEndpointParams,
}

const locale = 'ja-JP';
const currency = 'JPY';

const buildSearchUrl = (
  defaultParams: SearchEndpointParams,
  priceRange: PriceRange,
  serviceTypes: ServiceType[],
) => {
  const params: SearchEndpointParams = { ...defaultParams };

  if (priceRange.min === 0) {
    delete params.minPrice;
  } else {
    params.minPrice = String(priceRange.min);
  }

  if (priceRange.max === maxPrice()) {
    delete params.maxPrice;
  } else {
    params.maxPrice = String(priceRange.max);
  }

  if (serviceTypes.length === 0) {
    delete params.serviceTypes;
  } else {
    params.serviceTypes = serviceTypes.map((st) => st.uid).join(',');
  }

  let pathnames = window.location.pathname.split('/');
  for (let i = pathnames.length - 1; i >= 0; i--) {
    if (pathnames[i] === 'page') {
      pathnames.splice(i, 2);
      break;
    }
  }

  const searchParams = new URLSearchParams(params);
  searchParams.sort();
  return [pathnames.join('/'), searchParams.toString()].filter(s => s).join('?');
};

const SearchFilterer: React.FC<Props> = ({
  serviceTypeOptions,
  fxRate,
  currency,
  defaultParams = {},
}) => {
  const [priceRange, setPriceRange] = useState<PriceRange>(() => {
    return {
      min: Number(defaultParams.minPrice ?? 0),
      max: Number(defaultParams.maxPrice ?? maxPrice()),
    };
  });
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>(() => {
    const defaultUids = defaultParams.serviceTypes?.split(',') ?? [];
    return serviceTypeOptions.filter((option) => defaultUids.includes(option.uid));
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(bpMobile);
  const [searchUrl, setSearchUrl] = useState<string>(() =>
    buildSearchUrl(defaultParams, priceRange, serviceTypes)
  );

  useEffect(() => {
    setSearchUrl(buildSearchUrl(defaultParams, priceRange, serviceTypes));
  }, [priceRange, serviceTypes]);

  useEffect(() => {
    responsive();
    window.addEventListener('resize', responsive);
    return () => {
      window.removeEventListener('click', closeFilter);
      window.removeEventListener('resize', responsive);
    }
  }, []);

  useEffect(() => {
    responsive();
    window.addEventListener('resize', responsive);
    return () => {
      window.removeEventListener('click', closeFilter);
      window.removeEventListener('resize', responsive);
    }
  }, []);

  const responsive = () => {
    setWidth(window.innerWidth);
  }

  const openFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    window.addEventListener('click', closeFilter);
    setIsFilterOpen(true);
  }

  const closeFilter = (event: Event | React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    window.removeEventListener('click', closeFilter);
    setIsFilterOpen(false);
  }

  const getFilterCount = () => {
    return (
      (priceRange.min === 0 && priceRange.max === maxPrice() ? 0 : 1) + (isEmpty(serviceTypes) ? 0 : 1)
    );
  };

  const internationalizedPrice = (price) => {
    return (price * (fxRate ? fxRate : 1));
  }

  return (
    <div className="c-searchFilterer">

      <div className='c-searchFilterer_floating sp-only' style={{ display: width < bpMobile && isFilterOpen ? 'none' : 'inline-block' }} onClick={openFilter}>
        <div className='c-searchFilterer_content'>
          <i className='fas fa-sliders-h me-2'></i>
          <div>Filter</div>
          {getFilterCount() > 0 && <span>{getFilterCount()}</span>}
        </div>
      </div>

      <div className='c-searchFilterer_wrapper' style={{ display: width < bpMobile && !isFilterOpen ? 'none' : 'block' }} onClick={event => event.stopPropagation()}>
        <div className='c-searchFilterer_title'>
          <div className='c-titleM'>{window.i18n.t('components.restaurants.search_filterer.more_filters.title')}</div>
          <span className='c-searchFilterer_close-icon sp-only' onClick={closeFilter} ><i className='fas fa-times'></i></span>
        </div>

        <div className="searchFilterer_item-wrapper">

          <section className='c-searchFilterer_item'>
            <h3 className='c-searchFilterer_label'>{window.i18n.t('components.restaurants.search_filterer.price_range.title')}</h3>
            <div className='c-searchFilterer_priceRange_wrapper'>
              <span className='c-searchFilterer_priceRange)value'>{new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(internationalizedPrice(priceRange.min))}</span>
              <span className='c-searchFilterer_priceRange)value'>~</span>
              <span className='c-searchFilterer_priceRange)value'>{new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(internationalizedPrice(priceRange.max))}{priceRange.max === maxPrice() ? '+' : ''}</span>
            </div>
            <div className='c-searchFilterer_slider-wrapper'>
              <ReactSlider
                onChange={(newValues: number[]) => {
                  const newPriceRange = {
                    min: newValues[0],
                    max: newValues[1],
                  };
                  setPriceRange(newPriceRange);
                }}
                defaultValue={[priceRange.min, priceRange.max]}
                className='react-slider'
                thumbClassName='react-slider-thumb'
                trackClassName='react-slider-track'
                ariaLabel={['min price', 'max price']}
                pearling
                min={0}
                max={maxPrice()}
                step={1000}
                minDistance={1000}
              />
            </div>
          </section>

          <section className='c-searchFilterer_item'>
            <h3 className='c-searchFilterer_label'>{window.i18n.t('components.restaurants.other_conditions.more_filters.title')}</h3>
            {serviceTypeOptions.map((option) => (
              <div className='c-easyCheck' key={option.uid}>
                <label>
                  <input
                    type='checkbox'
                    checked={serviceTypes.find((st) => st.uid === option.uid) != undefined}
                    onChange={() => {
                      const newServiceTypes = xorBy(serviceTypes, [option], 'uid');
                      setServiceTypes(newServiceTypes);
                    }}
                  />
                  <div className='c-easyCheck_content'>
                    <div>{option.name}</div>
                  </div>
                </label>
              </div>
            ))}
          </section>

          <div className="c-searchFilterer_submit">
            <a href={searchUrl} className="btn btn-primary d-block">
              {window.i18n.t('components.restaurants.search_filterer.submit.button_text')}
            </a>
          </div>
        </div>

      </div>

    </div>
  );
}

export default SearchFilterer;
