import React, { useEffect, useState, useRef } from 'react';

type Props = {
  name: string;
};

const RatingField: React.FC<Props> = ({ name }) => {
  const [value, setValue] = useState<number>(0);
  const [cursor, setCursor] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const inputRef = useRef(null)

  useEffect(() => {
    // submitのタイミングで個別に星の評価チェック
    // 星がついていない場合はエラーメッセージ出す
    document.getElementById("review-form")
      .addEventListener('submit', (e) => {
        // value > 0の時だけinputタグが生成されるので、inputRefがnullのままということは評価されていない
        // ということなのでエラーとする
        if (inputRef.current === null) {
          setError(true);
          e.preventDefault();
          return false;
        } else if (inputRef.current.value > 0) {
          setError(false);
        }
        return true;
      });
  }, [])

  return (
    <div className='p-rRev_review_stars' onMouseLeave={() => setCursor(0)}>
      {value > 0 && <input type='hidden' name={name} value={value} ref={inputRef} />}
      {[1, 2, 3, 4, 5].map((i) => (
        <span key={i} onClick={() => setValue(i)} onMouseOver={() => setCursor(i)}>
          {i <= (cursor || value) ? '★' : '☆'}
        </span>
      ))}
      {error &&
        <div className='text-danger'>{window.i18n.t('components.restaurants.reviews.rating_field.text_danger')}</div>
      }
    </div>
  );
};

export default RatingField;
