import classNames from "classnames";
import React, { FC, useState } from "react";
type Props = {
  onChange: (tab: number) => void | (() => void);
  tabContents: string[];
};

export const Tab: FC<Props> = ({ onChange, tabContents }) => {
  const [tab, setTab] = useState<number>(0);

    const getTranslationKey = (content: string) => {
      switch (content) {
        case "tokyo":
          return 'components.home.tab_menu_area.tokyo';
        case "kyoto":
          return 'components.home.tab_menu_area.kyoto';
        case "osaka":
          return 'components.home.tab_menu_area.osaka';
        default:
          return '';
      }
    };
  return (
    <div className="p-topMostPopular">
      <ul className="nav nav-tabs nav-fill">
        {tabContents.map((content, index) => (
          <li className="nav-item" key={index}>
            <button
              className={classNames("nav-link", index === tab && "active")}
              onClick={() => {
                setTab(index);
                onChange(index);
              }}
            >
              {window.i18n.t(getTranslationKey(content))}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
