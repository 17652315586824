import React from "react";
import { formControlContext, FormFieldState } from "./form_control_context";

type Props = {
  name: string;
  placeholder: string;
  rows: number;
};

const SpecialRequestForm: React.FC<Props> = ({
  name,
  placeholder,
  rows,
}) => {
  const context: FormFieldState = React.useContext(formControlContext);

  return (
    <>
      {context.isDietaryRestrictionsSelectVegan && (
        <div className='mb-2 u-tx-alert'>{window.i18n.t('components.user.special_request_form.context.is_select_vegan.alert')}</div>
      )}
      {context.isDietaryRestrictionsSelectOther && (
        <div className='mb-2 u-tx-alert'>{window.i18n.t('components.user.special_request_form.context.is_select_other.alert')}</div>
      )}
      <textarea
        name={name}
        placeholder={placeholder}
        rows={rows}
        className='form-control'
        onChange={(e) => context.setIsSpecialRequestPresent(e.target.value.length > 0)}
        readOnly={context.isFormSending}
      />
    </>
  );
};

export default SpecialRequestForm;
