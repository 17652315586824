import * as React from 'react';
import Rails from 'rails-ujs';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import { StripeSourceCard } from './NewOrderForm';

const stripeWrapperStyle = {
  lineHeight: '1.21428571em',
  padding: '0.67857143em 1em',
  border: '1px solid rgba(34, 36, 38, 0.15)',
  borderRadius: '0.28571429rem',
};

const stripeElementStyle = {
  color: '#1a1a1a',
  '::placeholder': { color: '#ccc' },
  fontFamily: '"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Roboto", "Meiryo", sans-serif',
};

type Props = {
  isUpdate: boolean
  setNewStripeSourceCard: (stripeSourceCard: StripeSourceCard) => void
  setCardUpdateSuccessMessage: (message: string) => void
  closeModal: () => void
}

type CardStatus = 'ok' | 'ng'

const StripeCardForm: React.VFC<Props> = ({ isTemporaryReservation, isUpdate, setNewStripeSourceCard, setCardUpdateSuccessMessage, closeModal }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>('')
  const [cardInfoForTemporaryReservation, setCardInfoForTemporaryReservation] = React.useState<{
    cardNumber: string,
    nameOnTheCard: string,
    cardExpiry: string,
    cardCvc: string,
  }>({
    cardNumber: '',
    nameOnTheCard: '',
    cardExpiry: '',
    cardCvc: '',
  })
  const [cardStatuses, setCardStatuses] = React.useState<{
    cardNumber: CardStatus;
    cardExpiry: CardStatus;
    cardCvc: CardStatus;
  }>({
    cardNumber: 'ng',
    cardExpiry: 'ng',
    cardCvc: 'ng'
  })
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    // 再度開いた際にメッセージを空にしておく
    setCardUpdateSuccessMessage('');
    // 一時予約の場合は、カード情報を空にしておく
    if (isTemporaryReservation) {
      setCardInfoForTemporaryReservation({
        cardNumber: '',
        nameOnTheCard: '',
        cardExpiry: '',
        cardCvc: '',
      });
    }
  }, [])

  React.useEffect(() => {
    if (Object.values(cardStatuses).every((cs) => cs === 'ok') && name !== '') {
      setIsValid(true);
      return;
    }

    setIsValid(false);
  }, [cardStatuses, name])

  const handleInputName = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setCardInfoForTemporaryReservation({ ...cardInfoForTemporaryReservation, nameOnTheCard: e.currentTarget.value });
  }, [])

  const handleChange = React.useCallback(
    (e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent) => {

      const clonedCardStatus = { ...cardStatuses };
      if (e.error || !e.complete) {
        clonedCardStatus[e.elementType] = 'ng';
        setCardStatuses(clonedCardStatus);
        return;
      }

      clonedCardStatus[e.elementType] = 'ok';
      setCardStatuses(clonedCardStatus);

      setCardInfoForTemporaryReservation({ ...cardInfoForTemporaryReservation, [e.elementType]: e.value });
    }, [cardStatuses])

  const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsSubmitting(true);

    const cardElement = elements.getElement('cardNumber');
    let result = null;

    if (isTemporaryReservation) {
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
    } else {
      result = await stripe.createSource(cardElement, {
        owner: {
          name,
        },
      });

      if (result.error) {
        setError(result.error.message);
        setIsSubmitting(false);
        return;
      } else if (result.source.card.funding === 'debit') {
        setError('Debit card is not acceptable');
        setIsSubmitting(false);
        return;
      }
    }

    try {
      if (isTemporaryReservation) {
        const response = await axios.post('/stripe_payment_method', {
          stripe_payment_method_id: result.paymentMethod.id,
          authenticity_token: Rails.csrfToken(),
        });

        setNewStripeSourceCard({
          id: result.paymentMethod.id,
          last4: result.paymentMethod.card.last4,
          brand: result.paymentMethod.card.brand,
          brandImagePath: response.data.brandImagePath,
        });
      } else {
        const response = await axios.put('/stripe_source', {
          stripe_source_id: result.source.id,
          brand: result.source.card.brand,
          authenticity_token: Rails.csrfToken(),
        });

        setNewStripeSourceCard({
          id: result.source.id,
          last4: result.source.card.last4,
          brand: result.source.card.brand,
          brandImagePath: response.data.brandImagePath,
        });
      }

      setCardUpdateSuccessMessage(`${window.i18n.t('components.stripe_card_form.card_update_success_message')}`);
      closeModal();
    } catch (_error) {
      setError(`${window.i18n.t('components.stripe_card_form.error')}`);
      setIsSubmitting(false);
    }
  }, [stripe, elements, name, setCardUpdateSuccessMessage, closeModal])

  return (
    <form onSubmit={handleSubmit} className="ui form">
      {error && (
        <div className="alert alert-danger my-3">
          <p className="fw-bold">
            <i className="fas fa-exclamation-triangle" />
            {window.i18n.t('components.stripe_card_form.alert')}
          </p>
          <ul className="list">
            <li>{error}</li>
          </ul>
        </div>
      )}
      <div className="mb-3">
        <label>{window.i18n.t('components.stripe_card_form.form_item.card_number.label')}<span className="c-form_rq">{window.i18n.t('components.stripe_card_form.form_item.card_number.badge')}</span></label>
        <div style={stripeWrapperStyle}>
          <CardNumberElement
            options={{
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>{window.i18n.t('components.stripe_card_form.form_item.name_on_the_card.label')}<span className="c-form_rq">{window.i18n.t('components.stripe_card_form.form_item.name_on_the_card.badge')}</span></label>
        <div>
          <input type="text" className="form-control" placeholder="TARO YAMADA" onInput={handleInputName} />
        </div>
      </div>
      <div className="mb-3">
        <label>{window.i18n.t('components.stripe_card_form.form_item.valid_through.label')}<span className="c-form_rq">{window.i18n.t('components.stripe_card_form.form_item.valid_through.label')}</span></label>
        <div style={stripeWrapperStyle}>
          <CardExpiryElement
            options={{
              placeholder: `${window.i18n.t('components.stripe_card_form.form_item.valid_through.placeholder')}`,
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-4">
        <label>{window.i18n.t('components.stripe_card_form.form_item.cvc.label')}<span className="c-form_rq">{window.i18n.t('components.stripe_card_form.stripe_card_form.form_item.cvc.label')}</span></label>
        <div style={stripeWrapperStyle}>
          <CardCvcElement
            options={{
              placeholder: 'CVC',
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting}>
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm mx-3" />
        ) : (
          <span className="fw-bold">
            {isUpdate ? `${window.i18n.t('components.stripe_card_form.form_item.submit.button_text.is_update')}` : `${window.i18n.t('components.stripe_card_form.form_item.submit.button_text.is_register')}`}</span>
        )}
      </button>
    </form>
  )
};

export default StripeCardForm;
