import uniq from "lodash/uniq";
import React, { useState } from "react";
import DietaryRestrictionEditor from "./dietary_restriction_editor";
import DietaryRestrictionViewer from "./dietary_restriction_viewer";
import { formControlContext, FormFieldState } from "./form_control_context";

type Label = string;
export type Value = string;
export type Option = [Value, Label];

type Props = {
  name: string;
  options: Option[];
  moreOptions: Option[];
  moreLabel: string;
  defaultValues: Value[];
  guestNo: number;
};

const DietaryRestrictionField: React.FC<Props> = ({
  name,
  options,
  moreOptions,
  moreLabel,
  defaultValues,
  guestNo,
}) => {
  const [values, setValues] = useState<Value[]>(defaultValues);
  const [hasFocus, setHasFocus] = useState(false);
  const context: FormFieldState = React.useContext(formControlContext);

  React.useEffect(() => {
    if (defaultValues.includes('vegan')) {
      context.setDietaryRestrictionsVeganGuests((guestNos) => uniq([...guestNos, guestNo]));
    }
    if (defaultValues.includes('other')) {
      context.setDietaryRestrictionsOtherGuests((guestNos) => uniq([...guestNos, guestNo]));
    }
  }, []);

  const handleChange = (newValues) => {
    if (newValues.includes('vegan')) {
      context.setDietaryRestrictionsVeganGuests((guestNos) => uniq([...guestNos, guestNo]));
    } else {
      context.setDietaryRestrictionsVeganGuests((guestNos) => guestNos.filter((no) => no !== guestNo));
    }
    if (newValues.includes('other')) {
      context.setDietaryRestrictionsOtherGuests((guestNos) => uniq([...guestNos, guestNo]));
    } else {
      context.setDietaryRestrictionsOtherGuests((guestNos) => guestNos.filter((no) => no !== guestNo));
    }
    setValues(newValues);
  };

  return (
    <div
      className="c-multipleCheck"
      tabIndex={0}
      onFocus={() => !context.isFormSending && setHasFocus(true)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setHasFocus(false);
        }
      }}
    >
      {uniq(values).map((value) => (
        <input key={value} type="hidden" name={name} value={value} />
      ))}
      {hasFocus ? (
        <DietaryRestrictionEditor
          options={options}
          moreOptions={moreOptions}
          moreLabel={moreLabel}
          values={values}
          guestNo={guestNo}
          setHasFocus={setHasFocus}
          onChange={handleChange}
        />
      ) : (
        <DietaryRestrictionViewer
          options={[...options, ...moreOptions]}
          values={values}
          isNoRestrictions={context.dietaryRestrictionsNoRestrictionGuests.includes(guestNo)}
        />
      )}
    </div>
  );
};

export default DietaryRestrictionField;
