import React, { useEffect, useState } from 'react';
import { formControlContext, FormFieldState } from './form_control_context';
import UserProfile from "../../../types/UserProfile";

type Option = [Value, Label];
type Props = {
  defaultValues?: UserProfile;
  emailFormName: string;
  emailPlaceholder: string;
  phoneLocalNumberFormName: string;
  phoneLocalNumberPlaceholder: string;
  phoneCountryFormName: string;
  phoneCountryPlaceholder: string;
  nameFirstFormName: string;
  nameFirstPlaceholder: string;
  nameLastFormName: string;
  nameLastPlaceholder: string;
  nameFirstKanaFormName: string;
  nameFirstKanaPlaceholder: string;
  nameLastKanaFormName: string;
  nameLastKanaPlaceholder: string;
  phoneCountryOptions: Option[];
  isTemporaryReservation: boolean;
};

const UserProfileForm: React.FC<Props> = ({
  defaultValues,
  emailFormName,
  emailPlaceholder,
  phoneLocalNumberFormName,
  phoneLocalNumberPlaceholder,
  phoneCountryFormName,
  phoneCountryPlaceholder,
  nameFirstFormName,
  nameFirstPlaceholder,
  nameLastFormName,
  nameLastPlaceholder,
  nameFirstKanaFormName,
  nameFirstKanaPlaceholder,
  nameLastKanaFormName,
  nameLastKanaPlaceholder,
  phoneCountryOptions,
  isTemporaryReservation,
}) => {
  const context: FormFieldState = React.useContext(formControlContext);
  const [email, setEmail] = useState(defaultValues?.email || '');
  const [phoneLocalNumber, setPhoneLocalNumber] = useState(defaultValues?.phoneLocalNumber || '');
  const [phoneCountry, setPhoneCountry] = useState(defaultValues?.phoneCountry || '');
  const [nameFirst, setNameFirst] = useState(defaultValues?.nameFirst || '');
  const [nameLast, setNameLast] = useState(defaultValues?.nameLast || '');
  const [kanaFirst, setKanaFirst] = useState(defaultValues?.nameFirstKana || '');
  const [kanaLast, setKanaLast] = useState(defaultValues?.nameLastKana || '');

  useEffect(() => {
    let isValid = !!email && !!phoneLocalNumber && !!phoneCountry && !!nameFirst && !!nameLast;
    if (isTemporaryReservation === true) { isValid = isValid && !!kanaFirst && !!kanaLast; }
    context.setIsUserProfileEntered(isValid);
  }, [email, phoneLocalNumber, phoneCountry, nameFirst, nameLast, kanaFirst, kanaLast]);

  return (
    <>
      <div className='c-section'>
        <div className='mb-2'>
          <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.email.label')}<span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.email.badge')}</span></label>
          <input
            type="text"
            name={emailFormName}
            value={email !== null ? email : ""}
            placeholder={emailPlaceholder}
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='row mb-3'>
          <div className='col-md-6'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.phone_country.label')}<span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.phone_country.badge')}</span></label>
            <select
              name={phoneCountryFormName}
              value={phoneCountry !== null ? phoneCountry : ""}
              placeholder={phoneCountryPlaceholder}
              className="form-select"
              onChange={(e) => setPhoneCountry(e.target.value)}
            >
              <option value="" disabled>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.phone_country.option.default')}</option>
              {phoneCountryOptions.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.phone_local_number.label')}<span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.phone_local_number.badge')}</span></label>
            <input
              type="tel"
              name={phoneLocalNumberFormName}
              value={phoneLocalNumber !== null ? phoneLocalNumber : ""}
              placeholder={phoneLocalNumberPlaceholder}
              className="form-control"
              onChange={(e) => setPhoneLocalNumber(e.target.value)}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-md-6 u-sp-mb-s'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.first_name.label')}<span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.first_name.badge')}</span></label>
            <input
              type="text"
              name={nameFirstFormName}
              value={nameFirst !== null ? nameFirst : ""}
              placeholder={nameFirstPlaceholder}
              className="form-control"
              onChange={(e) => setNameFirst(e.target.value)}
            />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.last_name.label')}<span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.last_name.badge')}</span></label>
            <input
              type="text"
              name={nameLastFormName}
              value={nameLast !== null ? nameLast : ""}
              placeholder={nameLastPlaceholder}
              className="form-control"
              onChange={(e) => setNameLast(e.target.value)}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-md-6 u-sp-mb-s'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.first_name.in_english_letter')}{isTemporaryReservation && <span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.first_name.badge')}</span>}</label>
            <input
              type="text"
              name={nameFirstKanaFormName}
              value={kanaFirst !== null ? kanaFirst : ""}
              placeholder={nameFirstKanaPlaceholder}
              className="form-control"
              onChange={(e) => setKanaFirst(e.target.value)}
            />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.last_name.in_english_letter')}{isTemporaryReservation && <span className='badge bg-danger bg-secondary ms-2'>{window.i18n.t('components.user.new_reservation_requests.user_profile.section.last_name.badge')}</span>}</label>
            <input
              type="text"
              name={nameLastKanaFormName}
              value={kanaLast !== null ? kanaLast : ""}
              placeholder={nameLastKanaPlaceholder}
              className="form-control"
              onChange={(e) => setKanaLast(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileForm;
