import { MealCategory } from "../types/meal_category";
import { SeatCategory } from "../types/seat_category";
import { SeatType } from "../types/seat_type";

const bpMobile = 479;

const monthInMs = 31 * 24 * 60 * 60 * 1000;

const initialPrefecture = 'tokyo';
const initialDate = new Date();
const maxGuestsCount = 20;
const initialGuestsCount = 2;
const mealCategories: MealCategory[] = ['lunch', 'dinner'];

const itemsPerPage = 20;
const itemsPerApiRequest = 100;
const maxPrice = ((fxRate: number = 1) => {
  return (100_000 * (fxRate ? fxRate : 1));
});

const MichelinRatings = {
  'one-star': { ja: '一つ星', en: 'One Star' },
  'two-stars': { ja: '二つ星', en: 'Two Stars' },
  'three-stars': { ja: '三つ星', en: 'Three Stars' },
  'bib-gourmand': { ja: 'ビブグルマン', en: 'Bib Gourmand' },
  'selected-restaurant': { ja: 'セレクテッドレストラン', en: 'Selected Restaurant' },
}

const AwardRatings = {
  'hyakumeiten': '百名店',
  'gold': 'ゴールド',
  'silver': 'シルバー',
  'bronze': 'ブロンズ',
}

const seatTypeEmpty: SeatType = {
  uid: '',
  seatCategory: 'empty',
  title: 'Select Seat Type',
};

/**
 * 席種表示の並び順定義
 */
const SeatCategoryOrderIndex: { [seatCategory: SeatCategory] : number} = {
  "counter": 1,
  "table": 2,
  "room": 3,
  "any": 4,
}

export {
  bpMobile,
  monthInMs,
  initialPrefecture,
  initialDate,
  maxGuestsCount,
  initialGuestsCount,
  mealCategories,
  itemsPerPage,
  itemsPerApiRequest,
  maxPrice,
  seatTypeEmpty,
  MichelinRatings,
  AwardRatings,
  SeatCategoryOrderIndex,
};
