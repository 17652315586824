function set(name: string, value: string, expiresInMilliseconds?: number) {
  const d = new Date();
  let expires = '';
  if (expiresInMilliseconds) {
    d.setTime(d.getTime() + expiresInMilliseconds);
    expires = "expires=" + d.toUTCString();
  }
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function get(name: string) {
  let cname = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(';');
  for(let i = 0; i <cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cname) == 0) {
      return cookie.substring(cname.length, cookie.length);
    }
  }
  return '';
}

export default { set, get };
