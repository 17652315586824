import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
  show: boolean;
  onHide: () => void;
  reviewer: string;
  images: string[];
  selectedImageIndex: number;
};

const ImageGalleryModal: React.FC<Props> = ({ show, onHide, reviewer, images, selectedImageIndex }) => {
  // モーダルが開かれたときに特定の画像にスクロールする処理を追加
  useEffect(() => {
    if (show && images && images.length > 0 && selectedImageIndex !== undefined) {
      const selectedImgElement = document.getElementById(`image-${selectedImageIndex}`);
      selectedImgElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [show, selectedImageIndex]);

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         {reviewer}'s review images
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-rShow_imgGallery">
          <ul>
            {images.map((image_url, index) => (
            <li key={index} id={`image-${index}`}>
              <img
                src={image_url}
                alt={`${reviewer}'s review image ${index + 1}`}
                loading="lazy"
                style={{ width: '100%', height: 'auto' }}
                />
            </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageGalleryModal;
