import React from 'react';
/**
 * レストランのレビュースコアの星を表示(React版)
 * NOTE:
 * 本システムは、星表示ロジックはRailsとReactで両方あるので、こちらの処理を変える時はRails側も変えること
 * 処理自体は同じ処理を実装
 * app/helpers/restaurants_helper.rbのreview_score_starsメソッド
 * @param rating 評価値
 */
const StarComponent: React.FC<{ rating: number}> = ({ rating }) => {
  const int_part = Math.floor(rating)
  return (
    <>
      {rating > 0 ?
        [1, 2, 3, 4, 5].map((i) => (
          (() => {
            if (int_part >= i) {
              return <span key={i} className='me-1'><i className="fas fa-star"></i></span>
            } else if (int_part == (i - 1) && (rating - int_part) >= 0.5) {
              return <span key={i} className='me-1'><i className="fas fa-star-half-alt"></i></span>
            } else {
              return <span key={i} className='me-1'><i className="far fa-star"></i></span>
            }
          })()
        ))
        :
        (
          <div className='me-1'>N/A</div>
        )
      }
    </>
  )
};

export default StarComponent;