import React from "react";
import Countdown from 'react-countdown';

type Props = {
  timeLimit: Date;
}

const Timer: React.FC<Props> = ({
  timeLimit,
}) => {
  console.log(timeLimit);
  const formattedTime = (time: object) => {
    const hours = time.hours
    const minutes = time.minutes;
    const seconds = time.seconds.toString().padStart(2, '0');

    if (hours > 0) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  }

  return (
    <div className="timer">
      <i className='fas fa-stopwatch me-1'></i>
      <Countdown
        date={new Date(timeLimit)}
        renderer={props => <div>{formattedTime(props)}</div>}
      />
    </div>
  );
};

export default Timer;
