import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import snakecaseKeys from 'snakecase-keys';
import { SearchEndpointParams } from '../../types/filter_params';
import { MealCategory } from '../../types/meal_category';
import { bpMobile, maxGuestsCount, mealCategories } from '../../utils/constants';
import cookies from '../../utils/cookies';
import { monthInMs } from '../../utils/constants';
import { getDatesBetween, newDateFromDateString, toISODateString } from '../../utils/dates';
import differenceBy from 'lodash/differenceBy';
import {calcDaysBetween2days} from "../../utils/dates";
import { enUS, ko, zhCN, zhTW } from 'date-fns/locale';

interface Props {
  collapseOnMobile: boolean,
  minDate: string,
  defaultParams?: SearchEndpointParams,
};

const SearchBox: React.FC<Props> = ({
  collapseOnMobile,
  minDate,
  defaultParams = {}
}) => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [guestsCount, setGuestsCount] = useState<number>();
  const [mealCategory, setMealCategory] = useState<MealCategory>();
  const [queryString, setQueryString] = useState<string>();
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(bpMobile);
  const [excludedDates, setExcludedDates] = useState<Date[]>([]);
  const [requiredDate, setRequiredDate] = useState<boolean>(false);
  const [requiredTime, setRequiredTime] = useState<boolean>(false);
  const datePicker = useRef<DatePicker>();

  useEffect(() => {
    setInitialValues();
    responsive();
    fetchAvailableDates(new Date());
    window.addEventListener('resize', responsive);
    return () => {
      window.removeEventListener('click', closeForm);
      window.removeEventListener('resize', responsive);
    }
  }, []);

  useEffect(() => {
    if (guestsCount > 0) {
      cookies.set('guests_count', guestsCount.toString());
    } else {
      cookies.set('guests_count', '');
    }
  }, [guestsCount]);

  const translateToQuery = (values: {
    startDate: Date | undefined,
    endDate: Date | undefined,
    guestsCount: number | undefined,
    mealCategory: MealCategory | undefined,
  }): SearchEndpointParams => {
    const params = {}
    if (values.startDate) {
      params['startDate'] = toISODateString(values.startDate);
    }
    if (values.endDate) {
      params['endDate'] = toISODateString(values.endDate);
    }
    if (values.guestsCount > 0) {
      params['guestsCount'] = values.guestsCount.toString();
    }
    if (values.mealCategory) {
      params['mealCategory'] = values.mealCategory.toString();
    }
    return params;
  }

  const setInitialValues = () => {
    const initialValues = {
      startDate: defaultParams.startDate ? newDateFromDateString(defaultParams.startDate) : null,
      endDate: defaultParams.endDate ? newDateFromDateString(defaultParams.endDate) : null,
      guestsCount: defaultParams.guestsCount ? Number(defaultParams.guestsCount) : 0,
      mealCategory: defaultParams.mealCategory ? mealCategories.find(c => c === defaultParams.mealCategory) : ''
    };
    setStartDate(initialValues.startDate);
    setEndDate(initialValues.endDate);
    setGuestsCount(initialValues.guestsCount);
    setMealCategory(initialValues.mealCategory);
    setQueryString(new URLSearchParams(translateToQuery(initialValues)).toString());
  }

  const buildAndSetQueryString = (key?: string, value?: string) => {
    const query = translateToQuery({ startDate, endDate, guestsCount, mealCategory });
    if (key && value) {
      query[key] = value;
    } else if (key) {
      // valueがnullの場合はパラメータから削除
      delete query[key]
    }
    const searchParams = new URLSearchParams(query);
    searchParams.sort();
    setQueryString(searchParams.toString());
  };

  const toggleDatePicker = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    datePicker.current.setOpen(!datePicker.current.isCalendarOpen());
  }

  const responsive = () => {
    setWidth(window.innerWidth);
  }

  const openForm = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    window.addEventListener('click', closeForm);
    setIsFormOpen(true);
  }

  const closeForm = (event: Event | React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    window.removeEventListener('click', closeForm);
    setIsFormOpen(false);
  }

  const fetchAvailableDates = async (month: Date) => {
    const url = '/candidate_dates';
    try {
      const today = new Date();
      const toMonth = (new Date(month.getTime() + monthInMs));
      const allDates = getDatesBetween(today, toMonth);
      setExcludedDates(allDates);
      const response = await axios.get(url, {
        params: snakecaseKeys({
          startDate: toISODateString(today),
          endDate: toISODateString(new Date(month.getTime() + monthInMs)),
        })
      });
      if (response.status === 200) {
        const availableDates = Array.from(
          response.data.requestAvailableDates
            .concat(response.data.irsAvailableDates || [])
        ).map((dateStr) => newDateFromDateString(dateStr));
        const excludedDates = differenceBy(allDates, availableDates, toISODateString);
        setExcludedDates(excludedDates);
        if (startDate
              && endDate
              && !response.data.find(availableDate => availableDate === toISODateString(startDate))
              && !response.data.find(availableDate => availableDate === toISODateString(endDate))) {
          setStartDate(undefined);
          setEndDate(undefined);
        }
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      console.error(error);
      return (false);
    }
  };

  const doSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    if ((!!startDate && !!endDate && !mealCategory) || (!startDate && !endDate && !!mealCategory)) {
      let txt = '';
      if (!startDate && !endDate) {
        txt = 'Date';
        setRequiredDate(true);
        setRequiredTime(false);
      } else {
        txt = 'Time';
        setRequiredDate(false);
        setRequiredTime(true);
      }
      alert(`Please select the ${txt}.`)
      e.preventDefault();
      return false;
    }
  }

  const handleDatesChanges = (dates: [Date, Date]) =>  {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      const days = calcDaysBetween2days(startDate, endDate)
      if (days > 6) {
        alert('Please select within one week.')
        setStartDate(null)
        setEndDate(null)
        return
      }
    }
    setStartDate(startDate)
    setEndDate(endDate)
    buildAndSetQueryString('startDate', startDate ? toISODateString(startDate) : null);
    buildAndSetQueryString('endDate', endDate ? toISODateString(endDate) : null);
    setRequiredDate(!startDate && !endDate && !!mealCategory);
    setRequiredTime(!!startDate && !!endDate && !mealCategory);
  }

  const getLocale = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return ko;
    if (path.startsWith('/zh-cn')) return zhCN;
    if (path.startsWith('/zh-tw')) return zhTW;
    return enUS; // default locale
  };

  const getDateFormat = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return 'M월 d일';
    if (path.startsWith('/zh-cn') || path.startsWith('/zh-tw')) return 'M月d日';
    return 'MMM d'; // default locale
  };

  const getWeekStartsOn = () => {
    const path = window.location.pathname;
    if (path.startsWith('/ko')) return 0; // 日曜始まり
    return 1;
  };

  return (
    <>
      {/* <div className='c-spSearchBox' style={{display: collapseOnMobile && width < bpMobile ? 'block' : 'none'}} onClick={openForm}>
        <div className='c-spSearchBox_content'>
          <span className='c-spCollapsedSearch_date'>{date?.toDateString()}</span>
          <span>/</span>
          <span className='c-spCollapsedSearch_pSize'>{guestsCount} guests</span>
          <span>/</span>
          <span className='c-spCollapsedSearch_tSlot'>{mealCategory}</span>
        </div>
        <i className='fas fa-angle-down'></i>
      </div> */}

      <div className='c-rSearchBox'>
        {/* <div className='c-rSearchBox_editCond' style={{ display: collapseOnMobile && width < bpMobile ? 'block' : 'none' }} onClick={event => event.stopPropagation()}>
          <span onClick={closeForm}><i className="fas fa-times"></i></span>
          <div className='c-rSearchBox_editCond_title'>Edit search conditions</div>
        </div> */}
        <form className='c-rSearchBox_itemWrap'>
          <div className='c-rSearchBox_item_list'>
            <div className='c-rSearchBox_item react-datepicker-ignore-onclickoutside' onClick={(event) => toggleDatePicker(event)}>
              <div className='c-rSearchBox_item_icon'>
                <i className="far fa-calendar-alt"></i>
              </div>
              {requiredDate && <span className='me-1 text-danger'><i className='fas fa-info-circle'></i></span>}
              <label htmlFor='date'>{window.i18n.t('components.restaurants.search_box.date.label')}</label>
              <p className='c-rSearchBox_ph'>{startDate && endDate ? `${format(startDate, getDateFormat(), { locale: getLocale() })} - ${format(endDate, getDateFormat(), { locale: getLocale() })}` : `${window.i18n.t('components.restaurants.top_search_box.top_searchbox.date.option.default')}` }</p>
              <i className='fas fa-angle-down'></i>
              <div onClick={e => e.stopPropagation()}>
                <DatePicker
                  locale={getLocale()}
                  weekStartsOn={getWeekStartsOn()}
                  isClearable
                  selected={startDate}
                  onChange={handleDatesChanges}
                  onMonthChange={(month: Date) => fetchAvailableDates(month)}
                  ref={(_datePicker: DatePicker) => datePicker.current = _datePicker}
                  customInput={<></>}
                  minDate={new Date(minDate)}
                  excludeDates={excludedDates}
                  popperClassName='custom-react-datepicker'
                  showPopperArrow={false}
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 5],
                      },
                    }
                  ]}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                />
              </div>
            </div>
            <div className='c-rSearchBox_item' onClick={event => event.stopPropagation()}>
              <div className='c-rSearchBox_item_icon'>
                <i className="fas fa-user-friends"></i>
              </div>
              <label htmlFor='guestsCount'>{window.i18n.t('components.restaurants.search_box.guests_count.label')}</label>
              <p className='c-rSearchBox_ph'>
                {guestsCount ?
                  `${guestsCount} `
                  : `${window.i18n.t('components.restaurants.search_box.date.option.default')}`
                }
                {guestsCount > 1 ?
                  guestsCount > 1 ?
                    `${window.i18n.t('components.restaurants.search_box.guests_count.option.unit.is_plural')}`
                    : `${window.i18n.t('components.restaurants.search_box.guests_count.option.unit.is_singular')}`
                  : ''
                }
              </p>
              <i className='fas fa-angle-down'></i>
              <select
                id='guestsCount'
                className='c-rSearchBox_select'
                value={guestsCount}
                onChange={(event) => {
                  const selectedGuestCount = event.target.value ? parseInt(event.target.value) : 0;
                  setGuestsCount(selectedGuestCount);
                  buildAndSetQueryString('guestsCount', selectedGuestCount > 0 ? selectedGuestCount.toString() : null);
                }}
                aria-label="guestsCount"
              >
                <option value=""></option>
                {Array.apply(null, Array(maxGuestsCount))
                  .map((_option: null, index: number) =>
                    <option
                      key={index + 1}
                      value={index + 1}
                    >
                      {index + 1}
                    </option>)}
              </select>
            </div>

            <div className='c-rSearchBox_item' onClick={event => event.stopPropagation()}>
              <div className='c-rSearchBox_item_icon'>
                <i className="far fa-clock"></i>
              </div>
              {requiredTime && <span className='me-1 text-danger'><i className='fas fa-info-circle'></i></span>}
              <label htmlFor='mealCategory'>{window.i18n.t('components.restaurants.search_box.time.label')}</label>
              <p className='c-rSearchBox_ph'>{!!mealCategory
                  ? window.i18n.t(`meal_categories.${mealCategory}`)
                  : `${window.i18n.t('components.restaurants.search_box.time.option.default')}`}</p>
              <i className='fas fa-angle-down'></i>
              <select
                id='mealCategory'
                className='c-rSearchBox_select'
                value={mealCategory}
                onChange={(event) => {
                  setMealCategory(event.target.value as MealCategory);
                  buildAndSetQueryString('mealCategory', event.target.value);
                  setRequiredDate(!startDate && !endDate && !!event.target.value);
                  setRequiredTime(!!startDate && !!endDate && !event.target.value);
                }}
                aria-label="mealCategory"
              >
                <option value=""></option>
                {mealCategories
                  .map(option =>
                    <option
                      key={option}
                      value={option}
                    >
                      {window.i18n.t(`meal_categories.${option?.charAt(0) + option?.slice(1)}`)}
                    </option>)}
              </select>
            </div>
          </div>

          <div className='c-rSearchBox_submit'>
            <a href={`${window.location.pathname}${queryString ? `?${queryString}` : ''}`} aria-label="search_submit" >
              <button type='submit' className='pc-only c-rSearchBox_submitIcon' aria-label="search" onClick={doSearch}>
                <i className="fas fa-search"></i>
              </button>
              <button type='submit' className='sp-only btn btn-primary' aria-label="search" onClick={doSearch}>{window.i18n.t('components.restaurants.search_box.submit.button_text')}</button>
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchBox;
