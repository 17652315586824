import { format } from 'date-fns'
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Logo from '../../../images/google_on_white.png'
import { bpMobile } from "../../../utils/constants";
import { GoogleReview, Review } from "../../../types/review.tsx";
import StarComponent from "../../../common/StarComponent";
import parse from 'html-react-parser';
import ImageField from "./image_field.tsx";
import { splitByNewLine } from '../../../utils/strings';

type Props = {
  slug: string;
  initialReviews: Review[];
  initialPageNum: number;
  initialIsLastPage: boolean;
};

type ResponseStatus = 'PENDING' | 'OK' | 'RETRIABLE' | 'ERROR'

const ReviewList: React.FC<Props> = ({ slug, initialReviews, initialPageNum, initialIsLastPage }) => {
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>('PENDING')
  const currentPageNum = useRef<number>(initialPageNum)
  const [isLastPage, setIsLastPage] = useState<boolean>(initialIsLastPage)
  // レビューコンテンツ保持
  const [googleReviews, setGoogleReviews] = useState<GoogleReview[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  // レビュー表示要素参照
  const reviewContentsRef = useRef<Array<HTMLDivElement>>([]);
  const googleReviewContentsRef = useRef<Array<HTMLDivElement>>([]);
  // 各レビューの初期表示の高さ(端末により変える)
  // PCの場合は100px、SPの場合は120px
  // NOTE:
  // _restaurants.scssのp-rShow_reviews_contentのmax-heightの設定値と合わせること
  const [heightThreshold, setHeightThreshold] = useState<number>(window.innerWidth > bpMobile ? 100 : 120)

  useEffect(() => {
    // 既に初期画面表示時にレビューが表示されている時は表示されているレビューの表示処理をする。
    // また、ローディング画面は不要なのでresponseStatusをOKにして、その表示を停止する。
    // 初期画面表示されちえるレビューがない場合は、レビューをサーバーから取得し表示処理を実行
    if (initialReviews.length > 0) {
      setReviews(oldReviews => oldReviews.concat(initialReviews))
      setResponseStatus('OK')
    } else {
      fetchReviewsAndSetup()
    }
  }, [])

  useEffect(() => {
    setupReviews()
  }, [reviews])

  /**
   * レビュー取得関数
   */
  const fetchReviews = async () => {
    const url = `${window.i18n.locale_path}/restaurants/${slug}/reviews.json?page=${currentPageNum.current}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setReviews(oldReviews => oldReviews.concat(response.data['reviews']))
        if (response.data['googleReviews'].length > 0) {
          setGoogleReviews(oldReviews => oldReviews.concat(response.data['googleReviews']))
        }
        setIsLastPage(!!parseInt(response.data['isLastPage']))
        setResponseStatus('OK')
        currentPageNum.current++
      }
    } catch (error) {
      handleError(error)
    }
  }

  /**
   * エラー処理
   * 408(Timeout)の場合、再実行すると取得できるかもしれないので処理を分ける
   */
  const handleError = (error) => {
    if (error.response.status === 408) {
      // retryable
      setResponseStatus('RETRIABLE')
    } else {
      setResponseStatus('ERROR')
    }
  }

  /**
   * レビュー取得＆表示関数
   * レビューを取得し、コンテンツが長いものには「Show review」ボタンを表示
   */
  const fetchReviewsAndSetup = async () => {
    await fetchReviews()
    setupReviews()
  }

  /**
   * レビュー表示
   * コンテンツが長いレビューには「Show review」ボタンを表示
   */
  const setupReviews = () => {
    reviewContentsRef.current.forEach((item, index) => { processReview(item, reviews[index]) })
    googleReviewContentsRef.current.forEach((item, index) => { processReview(item, reviews[index]) })
  }

  /**
   * レビュー処理関数
   * 閾値より大きい場合は、show reviewボタンを表示し、要素した部分をグラデーションする
   * @param item 各レビューを表示しているdiv要素
   */
  const processReview = (item: HTMLDivElement) => {
    if (item.offsetHeight < heightThreshold) {
      item.classList.add('open')
    }
  }

  /**
   * レビュー用Show reviewボタンクリック時処理
   * @param index 表示要素の番号
   */
  const showMoreReview = (index: number) => {
    processShowMore(reviewContentsRef.current[index])
  }

  /**
   * Googleレビュー用Show reviewkボタンクリック時処理
   * @param index 表示要素の番号
   */
  const showMoreGoogleReview = (index: number) => {
    processShowMore(googleReviewContentsRef.current[index])
  }

  /**
   * Show reviewボタン処理メイン関数
   * ボタンが押されたあとは、コンテンツを全て表示し、ボタンとグラデーションを消す
   * @param target 処理対象のdiv要素
   */
  const processShowMore = (target: HTMLDivElement) => {
    target.classList.add('open')
  }

  /**
   * Components 実装
   */
  const UserReviewStarComponent: React.FC<{ review: Review }> = ({ review }) => {
    return (
      <>
        {review.averageScore > 0.0 &&
          <>
            <StarComponent rating={review.averageScore} />
            <b>
              <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {review.averageScore}
              </a>
              <div className='dropdown-menu'>
                <UserReviewStarDetailComponent review={review} />
              </div>
            </b>
          </>
        }
      </>
    )
  };

  const GoogleReviewStarComponent: React.FC<{ rating: number }> = ({ rating }) => {
    return (
      <>
        <StarComponent rating={rating} />
        <b>{rating}</b>
      </>
    )
  };
  const UserReviewStarDetailComponent: React.FC<{ review: Review }> = ({ review }) => {
    // locationは非使用になっているので、過去のレビューで評価されている場合だけ表示する
    // 評価されていないものは表示もしない
    return (
      <>
        <div className='p-rShow_reviews_stars_details'>
          <dl>
            {review.taste > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.taste')}</dt>
                <dd><StarComponent rating={review.taste} /></dd>
              </>
            }
            {review.atmosphere > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.atmosphere')}</dt>
                <dd><StarComponent rating={review.atmosphere} /></dd>
              </>
            }
            {review.service > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.service')}</dt>
                <dd><StarComponent rating={review.service} /></dd>
              </>
            }
            {review.location > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.location')}</dt>
                <dd><StarComponent rating={review.location} /></dd>
              </>
            }
            {review.valueForMoney > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.value_for_money')}</dt>
                <dd><StarComponent rating={review.valueForMoney} /></dd>
              </>
            }
            {review.communicationSupport > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.communication_support')}</dt>
                <dd><StarComponent rating={review.communicationSupport} /></dd>
              </>
            }
            {review.memorableExperience > 0 &&
              <>
                <dt>{window.i18n.t('components.restaurants.reviews.review_list.reviews_stars_details.memorable_experience')}</dt>
                <dd><StarComponent rating={review.memorableExperience} /></dd>
              </>
            }
          </dl>
        </div>
      </>
    )
  }

  const ReviewerCountryFlagComponent: React.FC<{ code: string }> = ({ code }) => {
    return (
      <span className={`fi fi-${code?.toLowerCase()}`} />
    )
  }

  const PlaceholderGlowComponent: React.FC = () => {
    return (
      <div className='placeholder-glow'>
        <div className='placeholder bg-secondary w-25 mb-2' ></div>
        <div className='placeholder bg-secondary w-100 mb-2' ></div>
        <div className='placeholder bg-secondary w-25 mb-2' ></div>
        <div className='placeholder bg-secondary w-100 mb-2' ></div>
        <div className='placeholder bg-secondary w-75 mb-2' ></div>
        <div className='placeholder bg-secondary w-75' ></div>
      </div>
    )
  }

  return (
    <>
      {responseStatus === 'PENDING' &&
        <PlaceholderGlowComponent />
      }
      {responseStatus === 'OK' &&
        <>
          <ul className='p-rShow_reviews_list'>
            {reviews.length > 0 &&
              <>
                {reviews.map((review, index) => {
                  if (review.comment) {
                    return review.isPinned ? (
                      <li className='p-rShow_reviews_item p-rShow_reviews_item-pinned'
                        key={review.id}>
                        <div className='p-rShow_reviews_item_header'>
                          <i className="fa-solid fa-thumbtack"></i>
                          <div className='p-rShow_reviews_item_overview'>
                            <i className="fas fa-user-circle"></i>
                            <div className='p-rShow_reviews_item_review'>
                              <div className='p-rShow_reviews_item_reviewerName'>
                                <h3 className='me-2 mb-0'>{review.displayName}</h3>
                                {review.country &&
                                  <ReviewerCountryFlagComponent code={review.country} />
                                }
                              </div>
                              <div className='p-rShow_reviews_stars'>
                                <UserReviewStarComponent review={review} />
                              </div>
                            </div>
                          </div>
                          <div className='p-rShow_reviews_posted'>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.posted_on')} : {format(Date.parse(review.createdAt), 'MM/dd/yyyy')} </div>
                        </div>
                        <div className='p-rShow_reviews_content' ref={(el) => (reviewContentsRef.current[index] = el)} data-review-id={review.id}>
                          {splitByNewLine(review.comment).map((line, index) => (
                            <span key={index}>
                              {index !== 0 && <br />}
                              {line}
                            </span>
                          ))}
                          <div className='text-center p-rShow_reviews_show_more'>
                            <button className='btn btn-sm btn-secondary rounded-pill' onClick={() => showMoreReview(index)}>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.show_more')}</button>
                          </div>
                        </div>
                        {review.approved_image_urls.length > 0 && (
                          <ImageField images={review.approved_image_urls} reviewer={review.displayName} />
                        )}
                      </li>
                    ) : (
                      <li className='p-rShow_reviews_item'
                        key={review.id}>
                        <div className='p-rShow_reviews_item_header'>
                          <div className='p-rShow_reviews_item_overview'>
                            <i className="fas fa-user-circle"></i>
                            <div className='p-rShow_reviews_item_review'>
                              <div className='p-rShow_reviews_item_reviewerName'>
                                <h3 className='me-2 mb-0'>{review.displayName}</h3>
                                {review.country &&
                                  <ReviewerCountryFlagComponent code={review.country} />
                                }
                              </div>
                              <div className='p-rShow_reviews_stars'>
                                <UserReviewStarComponent review={review} />
                              </div>
                            </div>
                          </div>
                          <div className='p-rShow_reviews_posted'>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.posted_on')} : {format(Date.parse(review.createdAt), 'MM/dd/yyyy')} </div>
                        </div>
                        <div className='p-rShow_reviews_content' ref={(el) => (reviewContentsRef.current[index] = el)} data-review-id={review.id}>
                          {splitByNewLine(review.comment).map((line, index) => (
                            <span key={index}>
                              {index !== 0 && <br />}
                              {line}
                            </span>
                          ))}
                          <div className='text-center p-rShow_reviews_show_more'>
                            <button className='btn btn-sm btn-secondary rounded-pill' onClick={() => showMoreReview(index)}>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.show_more')}</button>
                          </div>
                        </div>
                        {review.approved_image_urls.length > 0 && (
                          <ImageField images={review.approved_image_urls} reviewer={review.displayName} />
                        )}
                      </li>
                    )
                  }
                })}
              </>
            }
            {googleReviews.length > 0 &&
              <>
                {googleReviews.map((review, index) => {
                  if (review.text) {
                    return (
                      <li className='p-rShow_reviews_item'
                        key={review.authorName}>
                        <div className='p-rShow_reviews_item_header'>
                          <div className='p-rShow_reviews_item_overview'>
                            <img className='google_reviewer_icon' src={review.profilePhotoUrl} alt="" />
                            <div className='p-rShow_reviews_item_review'>
                              <div className='p-rShow_reviews_item_reviewerName'>
                                <h3 className='me-2 mb-0'>{review.authorName}</h3>
                              </div>
                            </div>
                            <div className='p-rShow_reviews_stars'>
                              <GoogleReviewStarComponent rating={review.rating} />
                            </div>
                          </div>
                          {/* review.timeはunix時間なので1000してDateの仕様に合わせる */}
                          <div className='p-rShow_reviews_posted'>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.posted_on')} : {format(new Date(review.time * 1000), 'MM/dd/yyyy')} </div>
                        </div>
                        <div className='p-rShow_reviews_content' ref={(el) => (googleReviewContentsRef.current[index] = el)}>
                          {review.text}
                          <div className='text-center p-rShow_reviews_show_more'>
                            <button className='btn btn-sm btn-secondary rounded-pill' onClick={() => showMoreGoogleReview(index)}>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.show_more')}</button>
                          </div>
                        </div>
                        {review.translated &&
                          <div className='p-rShow_reviews_translate'>{window.i18n.t('components.restaurants.reviews.review_list.reviews_item.reviews_translate')}</div>
                        }
                      </li>
                    )
                  }
                })}
              </>
            }
          </ul>
          {googleReviews.length > 0 &&
            <div className='mt-1'>
              <img src={Logo} alt="Powered by Google." width='59px' height='18px' />
            </div>
          }
          {!isLastPage &&
            <>
              <div className='text-center m-2'>
                {reviews.length < 20 ?
                  (<button className='btn btn-outline-secondary rounded-pill'
                    onClick={(event) => fetchReviewsAndSetup()}>
                    {window.i18n.t('components.restaurants.reviews.review_list.google_reviews.button_text')}
                  </button>) :
                  (<a href={`${window.i18n.locale_path}/restaurants/${slug}/reviews`} className='btn btn-outline-secondary rounded-pill'>
                    {window.i18n.t('components.restaurants.reviews.review_list.google_reviews.button_text')}
                  </a >)
                }
              </div>
            </>
          }
        </>
      }
      {responseStatus === 'RETRIABLE' &&
        <div className='w-100 text-center'>
          <button className='w-50 btn btn-outline-danger'
            onClick={(event) => fetchReviewsAndSetup()} >
            {parse(window.i18n.t('components.restaurants.reviews.review_list.retriable.button_text'))}
          </button>
        </div>
      }
      {responseStatus === 'ERROR' &&
        <div className='text-center text-danger'>{window.i18n.t('components.restaurants.reviews.review_list.retriable.error')}</div>
      }
    </>
  );
};

export default ReviewList;
