import React, { useEffect } from "react";
import { FormFieldStateProvider } from "./form_control_context";
import DietaryRestrictionField from "./dietary_restriction_field";
import SpecialRequestForm from "./special_request_form";
import UserProfileForm from "./user_profile_form";
import SubmitButton from "./submit_button";
import RequestChecklist from "./request_checklist";
import StripeCardField from "../../StripeCardField";
import UserProfile from "../../../types/UserProfile";
import { formControlContext, FormFieldState } from './form_control_context';
import parse from 'html-react-parser';

type Label = string;
export type Value = string;
export type Option = [Value, Label];

type StripeSourceCard = {
  id: string;
  last4: string;
  brand: string;
  brandImagePath: string;
}

type StripeCardFieldProps = {
  stripePublicKey: string;
  stripeSourceCard: StripeSourceCard | null;
}

type DietaryRestrictionFieldProps = {
  name: string;
  options: Option[];
  moreOptions: Option[];
  moreLabel: string;
  defaultValues: Value[];
  guestNo: number;
}

type SpecialRequestFormProps = {
  name: string;
  rows: number;
  placeholder: string;
}

type UserProfileFormProps = {
  emailFormName: string;
  emailPlaceholder: string;
  phoneLocalNumberFormName: string;
  phoneLocalNumberPlaceholder: string;
  phoneCountryFormName: string;
  phoneCountryPlaceholder: string;
  nameFirstFormName: string;
  nameFirstPlaceholder: string;
  nameLastFormName: string;
  nameLastPlaceholder: string;
  nameFirstKanaFormName: string;
  nameFirstKanaPlaceholder: string;
  nameLastKanaFormName: string;
  nameLastKanaPlaceholder: string;
  phoneCountryOptions: Option[];
  defaultValues?: UserProfile;
}

type PartnerProps = {
  balance: number;
  attention_messages: string[];
}

type ReservationNoteOnDietaryRestrictionsField = {
  text: string;
  title: string;
}

type ReservationNoteOnArrivalTimeField = {
  text: string;
  title: string;
}

type ReservationNoteOnDressCodeField = {
  text: string;
  title: string;
}

type ReservationNoteOnChildrenField = {
  text: string;
  title: string;
}

type ReservationNoteOnSpecificOthersField = {
  text: string;
  title: string;
}

type SubmitButtonProps = {
  text: string;
  className: string;
}

type Props = {
  dietaryRestrictionFields: DietaryRestrictionFieldProps[];
  specialRequestForm: SpecialRequestFormProps;
  userProfileForm: UserProfileFormProps;
  reservationNoteOnDietaryRestrictionsField: ReservationNoteOnDietaryRestrictionsField;
  reservationNoteOnArrivalTimeField: ReservationNoteOnArrivalTimeField;
  reservationNoteOnDressCodeField: ReservationNoteOnDressCodeField;
  reservationNoteOnChildrenField: ReservationNoteOnChildrenField;
  reservationNoteOnSpecificOthersField: ReservationNoteOnSpecificOthersField;
  submitButton: SubmitButtonProps;
  stripeCardField: StripeCardFieldProps | null;
  partner: PartnerProps | null;
  checklistLabels: string[];
  reservationType: string;
  isTemporaryReservation: boolean;
  loginButtonId: string | null;
  formId: string;
  omakaseTermsUrl: string | null;
  omakasePrivacyPolicyUrl: string | null;
  jeTermsUrl: string | null;
};


const FormGroup: React.FC<Props> = ({
  stripeCardField,
  partner,
  dietaryRestrictionFields,
  reservationNoteOnDietaryRestrictionsField,
  reservationNoteOnArrivalTimeField,
  reservationNoteOnDressCodeField,
  reservationNoteOnChildrenField,
  reservationNoteOnSpecificOthersField,
  specialRequestForm,
  userProfileForm,
  checklistLabels,
  submitButton,
  reservationType,
  isTemporaryReservation,
  loginButtonId,
  formId,
  omakaseTermsUrl,
  omakasePrivacyPolicyUrl,
  jeTermsUrl,
}) => {
  const context: FormFieldState = React.useContext(formControlContext);

  useEffect(() => {
    if (isTemporaryReservation) {
      const beforeunloadFunc = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
      }

      // NOTE: reload or close window or 離脱時に警告を出す
      window.addEventListener("beforeunload", beforeunloadFunc);
      const formObj = document.getElementById(formId);
      formObj.addEventListener('submit', () => {
        window.removeEventListener("beforeunload", beforeunloadFunc);
      });
      const loginButton = document.getElementById(loginButtonId);
      loginButton?.addEventListener('click', () => {
        window.removeEventListener("beforeunload", beforeunloadFunc);
      });
    }
  }, []);

  // NOTE: 暫定対応
  //       window.i18n.t でできるようにするとわかりやすい
  const termsTexts = window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.is_temporary_reservation.message')
    .replace(/\{\`\$\{omakaseTermsUrl\}\`\}/i, `"${omakaseTermsUrl}"`)
    .replace(/\{\`\$\{jeTermsUrl\}\`\}/i, `"${jeTermsUrl}"`)
    .replace(/\{\`\$\{omakasePrivacyPolicyUrl\}\`\}/i, `"${omakasePrivacyPolicyUrl}"`);

  return (
    <FormFieldStateProvider>
      {!partner && <div className='p-rsvRequest_item'>
        <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.payment_method.title')}</h2>
        <div className='c-section'>
          <StripeCardField
            stripePublicKey={stripeCardField?.stripePublicKey}
            stripeSourceCard={stripeCardField?.stripeSourceCard}
            isTemporaryReservation={isTemporaryReservation}
          />
        </div>
        {reservationType === 'request' && <div className='p-rsvRequest_item_caution mt-2'>
          {window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.payment_method.caution')}
        </div>}
      </div>}

      {isTemporaryReservation && <div className='p-rsvRequest_item'>
        <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.is_temporary_reservation.title')}</h2>
        <UserProfileForm
          defaultValues={userProfileForm.defaultValues}
          emailFormName={userProfileForm.emailFormName}
          emailPlaceholder={userProfileForm.emailPlaceholder}
          phoneLocalNumberFormName={userProfileForm.phoneLocalNumberFormName}
          phoneLocalNumberPlaceholder={userProfileForm.phoneLocalNumberPlaceholder}
          phoneCountryFormName={userProfileForm.phoneCountryFormName}
          phoneCountryPlaceholder={userProfileForm.phoneCountryPlaceholder}
          nameFirstFormName={userProfileForm.nameFirstFormName}
          nameFirstPlaceholder={userProfileForm.nameFirstPlaceholder}
          nameLastFormName={userProfileForm.nameLastFormName}
          nameLastPlaceholder={userProfileForm.nameLastPlaceholder}
          nameFirstKanaFormName={userProfileForm.nameFirstKanaFormName}
          nameFirstKanaPlaceholder={userProfileForm.nameFirstKanaPlaceholder}
          nameLastKanaFormName={userProfileForm.nameLastKanaFormName}
          nameLastKanaPlaceholder={userProfileForm.nameLastKanaPlaceholder}
          phoneCountryOptions={userProfileForm.phoneCountryOptions}
          isTemporaryReservation={isTemporaryReservation}
        />
      </div>}

      {partner && <>
        <div className='p-rsvRequest_item'>
          <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.partner.account_balance.title')}</h2>
          <div className='c-section'>
            <div className="p-checkout_card">
              <div className="p-checkout_card_detail">
                <span>JPY {partner.balance.toLocaleString('ja-JP')}</span>
              </div>
              <a href="/agent/partner_incoming_bank_transfers/new" className="btn btn-sm btn-outline-primary">{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.partner.account_balance.button_text')}</a>
            </div>
          </div>
        </div>

        <div className='p-rsvRequest_item'>
          <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.partner.customers_profile.title')}</h2>
          <UserProfileForm
            emailFormName={userProfileForm.emailFormName}
            emailPlaceholder={userProfileForm.emailPlaceholder}
            phoneLocalNumberFormName={userProfileForm.phoneLocalNumberFormName}
            phoneLocalNumberPlaceholder={userProfileForm.phoneLocalNumberPlaceholder}
            phoneCountryFormName={userProfileForm.phoneCountryFormName}
            phoneCountryPlaceholder={userProfileForm.phoneCountryPlaceholder}
            nameFirstFormName={userProfileForm.nameFirstFormName}
            nameFirstPlaceholder={userProfileForm.nameFirstPlaceholder}
            nameLastFormName={userProfileForm.nameLastFormName}
            nameLastPlaceholder={userProfileForm.nameLastPlaceholder}
            nameFirstKanaFormName={userProfileForm.nameFirstKanaFormName}
            nameFirstKanaPlaceholder={userProfileForm.nameFirstKanaPlaceholder}
            nameLastKanaFormName={userProfileForm.nameLastKanaFormName}
            nameLastKanaPlaceholder={userProfileForm.nameLastKanaPlaceholder}
            phoneCountryOptions={userProfileForm.phoneCountryOptions}
          />
          {partner && <div className='alert alert-warning mt-3'>
            {partner.attention_messages.map((message, index) => (
              <div key={index}>
                <i className="fa-solid fa-triangle-exclamation me-2"></i>
                {parse(message)}
              </div>
            ))}
          </div>}
        </div>
      </>}

      <div className='p-rsvRequest_item'>
        <h2 className='c-titleM'>
          {partner ?
            window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.dietary_restrictions.title.is_partner')
            : window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.dietary_restrictions.title.default')
          }
        </h2>
        <div className='p-rsvRequest_dr'>
          <div className='mb-2'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.dietary_restrictions.dr')}</div>
          {dietaryRestrictionFields.map((dietaryRestrictionField) =>
            <div className='p-rsvRequest_dr_input' key={dietaryRestrictionField.guestNo}>
              <label>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.dietary_restrictions.dr_input.label')} {dietaryRestrictionField.guestNo} <span className='badge bg-danger bg-secondary'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.dietary_restrictions.dr_input.badge')}</span></label>
              <DietaryRestrictionField
                name={dietaryRestrictionField.name}
                options={dietaryRestrictionField.options}
                moreOptions={dietaryRestrictionField.moreOptions}
                moreLabel={dietaryRestrictionField.moreLabel}
                defaultValues={dietaryRestrictionField.defaultValues}
                guestNo={dietaryRestrictionField.guestNo}
              />
            </div>
          )}
        </div>
      </div>

      <div className='p-rsvRequest_item'>
        <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.special_request.title')}</h2>
        <SpecialRequestForm
          name={specialRequestForm.name}
          placeholder={specialRequestForm.placeholder}
          rows={specialRequestForm.rows}
        />
      </div>

      {(!!reservationNoteOnDietaryRestrictionsField.text ||
        !!reservationNoteOnArrivalTimeField.text ||
        !!reservationNoteOnDressCodeField.text ||
        !!reservationNoteOnChildrenField.text ||
        !!reservationNoteOnSpecificOthersField.text)
        && <div className='p-rsvRequest_item'>
          <h2 className='c-titleM'>{window.i18n.t('components.user.new_reservation_requests.form_group.rsv_request_item.reservation_notes.title')}</h2>
          <ul>
            {!!reservationNoteOnDietaryRestrictionsField.text && <>
              <li>
                <b>{reservationNoteOnDietaryRestrictionsField.title}:</b>
                {reservationNoteOnDietaryRestrictionsField.text && parse(reservationNoteOnDietaryRestrictionsField.text)}
              </li>
            </>}
            {!!reservationNoteOnArrivalTimeField.text && <>
              <li>
                <b>{reservationNoteOnArrivalTimeField.title}:</b>
                {reservationNoteOnArrivalTimeField.text && parse(reservationNoteOnArrivalTimeField.text)}
              </li>
            </>}
            {!!reservationNoteOnDressCodeField.text && <>
              <li>
                <b>{reservationNoteOnDressCodeField.title}:</b>
                {reservationNoteOnDressCodeField.text && parse(reservationNoteOnDressCodeField.text)}
              </li>
            </>}
            {!!reservationNoteOnChildrenField.text && <>
              <li>
                <b>{reservationNoteOnChildrenField.title}:</b>
                {reservationNoteOnChildrenField.text && parse(reservationNoteOnChildrenField.text)}
              </li>
            </>}
            {!!reservationNoteOnSpecificOthersField.text && <>
              <li>
                <b>{reservationNoteOnSpecificOthersField.title}:</b>
                {reservationNoteOnSpecificOthersField.text && parse(reservationNoteOnSpecificOthersField.text)}
              </li>
            </>}
          </ul>
        </div>}

      <div className='p-rsvRequest_item'>
        <RequestChecklist
          checklistLabels={checklistLabels}
        />
      </div>

      {isTemporaryReservation && <div className="p-rsvRequest_item">
        {parse(termsTexts)}
      </div>}

      <div className='p-rsvRequest_item text-center p-rsvRequest_checkout'>
        <SubmitButton
          text={submitButton.text}
          className={submitButton.className}
          partner={partner}
          isTemporaryReservation={isTemporaryReservation}
          guestCount={dietaryRestrictionFields.length}
        />
      </div>
    </FormFieldStateProvider>
  );
};

export default FormGroup;
export type { PartnerProps };
